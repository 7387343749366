import react, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RecentActionsDataRead } from '../../../redux/recentActions/actionCreator';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Table } from 'antd';
import { BorderLessHeading, TableDefaultStyle } from '../../styled';
import { TopSellerWrap } from '../styles/dashboardStyle';
import { Link } from 'react-router-dom';
import { UilExternalLinkAlt } from '@iconscout/react-unicons'

function RecentActions({RecentActions}) {

  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(RecentActionsDataRead());
  // }, [dispatch])

  // const { RecentActions } = useSelector((state) => {
  //   return {
  //     RecentActions: state.RecentActions.data,
  //   };
  // });

  const columns = [
    {
      title: 'Subject',
      dataIndex: 'subjectname',
      key: 'subjectname',      
    },
    {
      title: 'Topic',
      dataIndex: 'topicname',
      key: 'topicname',      

    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',      

    },
    // {
    //   title: 'Info',
    //   dataIndex: 'info',
    //   key: 'info',
    // },
    {
      title: '',
      dataIndex: 'link',
      key: 'link',
    },
  ];

  let data = []

  RecentActions.length !== 0 && RecentActions.map((obj, index) => {
    const { subjectId, subjectname, unitId, topicId, topicname, info, action, createdon } = obj
    data.push({
      key: index,
      subjectId,
      subjectname,
      unitId,
      topicId,
      topicname,
      info,
      action,
      createdon,
      link: <div className='table-actions'>
        <Link to={`subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}`}><UilExternalLinkAlt style={{ width: '18px' }} /></Link>
      </div>
    })
  })

  return (
    <div>
      <BorderLessHeading>
        <Cards title='My Recent Actions'>
          <TableDefaultStyle className="ninjadash-having-header-bg" style={{minHeight:'294px'}}>
            <TopSellerWrap>
              <div className="table-bordered top-seller-table table-responsive">
                <Table columns={columns} dataSource={data} pagination={false} />
              </div>
            </TopSellerWrap>
          </TableDefaultStyle>
        </Cards>
      </BorderLessHeading>
    </div>
  );
}

export default RecentActions