import actions from './actions';

const {
  TESSELLATOR_TOPICS_READ_BEGIN,
  TESSELLATOR_TOPICS_READ_SUCCESS,
  TESSELLATOR_TOPICS_READ_ERR,

  TESSELLATOR_TOPIC_PROGRAMS_READ_BEGIN,
  TESSELLATOR_TOPIC_PROGRAMS_READ_SUCCESS,
  TESSELLATOR_TOPIC_PROGRAMS_READ_ERR,

  MAP_TESSELLATOR_TOPIC_UPDATE_BEGIN,
  MAP_TESSELLATOR_TOPIC_UPDATE_SUCCESS,
  MAP_TESSELLATOR_TOPIC_UPDATE_ERR,

  MAP_TESSELLATOR_TOPIC_TO_NULL_UPDATE_BEGIN,
  MAP_TESSELLATOR_TOPIC_TO_NULL_UPDATE_SUCCESS,
  MAP_TESSELLATOR_TOPIC_TO_NULL_UPDATE_ERR,

} = actions;

const initialTopicsState = {
  data: [],
  loading: false,
  error: null,
};

const initialProgramsState = {
  data: [],
  loading: false,
  error: null,
};

const MapResponse = {
  data: null,
  loading: false,
  error: null,
};

const MapResponseToNull = {
  data: null,
  loading: false,
  error: null,
};

const TessellatorTopicsReducer = (state = initialTopicsState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case TESSELLATOR_TOPICS_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TESSELLATOR_TOPICS_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case TESSELLATOR_TOPICS_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const TessellatorTopicPogramsReducer = (state = initialProgramsState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case TESSELLATOR_TOPIC_PROGRAMS_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TESSELLATOR_TOPIC_PROGRAMS_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case TESSELLATOR_TOPIC_PROGRAMS_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const MapTessellatorTopicReducer = (state = MapResponse, action) => {
  const { type, data, err } = action;
  switch (type) {
    case MAP_TESSELLATOR_TOPIC_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case MAP_TESSELLATOR_TOPIC_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case MAP_TESSELLATOR_TOPIC_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const MapTessellatorTopicToNullReducer = (state = MapResponseToNull, action) => {
  const { type, data, err } = action;
  switch (type) {
    case MAP_TESSELLATOR_TOPIC_TO_NULL_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case MAP_TESSELLATOR_TOPIC_TO_NULL_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case MAP_TESSELLATOR_TOPIC_TO_NULL_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { TessellatorTopicsReducer, TessellatorTopicPogramsReducer, MapTessellatorTopicReducer, MapTessellatorTopicToNullReducer };
