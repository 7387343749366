export const GET_ANSWERS_BEGIN = 'GET_ANSWERS_BEGIN';
export const GET_ANSWERS_SUCCESS = 'GET_ANSWERS_SUCCESS';
export const GET_ANSWERS_ERROR = 'GET_ANSWERS_ERROR';

export const MANUAL_EVALUATION_BEGIN = 'MANUAL_EVALUATION_BEGIN';
export const MANUAL_EVALUATION_SUCCESS = 'MANUAL_EVALUATION_SUCCESS';
export const MANUAL_EVALUATION_ERROR = 'MANUAL_EVALUATION_ERROR';

export const READ_QA_REPORTS_BEGIN = 'READ_QA_REPORTS_BEGIN';
export const READ_QA_REPORTS_SUCCESS = 'READ_QA_REPORTS_SUCCESS';
export const READ_QA_REPORTS_ERROR = 'MANUAL_EVALUATION_ERROR';

export const readAnswersBegin = () => ({
    type: GET_ANSWERS_BEGIN,
});

export const readAnswersSuccess = (data) => ({
    type: GET_ANSWERS_SUCCESS,
    payload: data,
});

export const readAnswersError = (error) => ({
    type: GET_ANSWERS_ERROR,
    payload: error,
});

export const manualEvaluationBegin = () => ({
    type: MANUAL_EVALUATION_BEGIN,
});

export const manualEvaluationSuccess = (data) => ({
    type: MANUAL_EVALUATION_SUCCESS,
    payload: data,
});

export const manualEvaluationError = (error) => ({
    type: MANUAL_EVALUATION_ERROR,
    payload: error,
});

export const readQaReportsBegin = () => ({
    type: READ_QA_REPORTS_BEGIN,
});

export const readQaReportsSuccess = (data) => ({
    type: READ_QA_REPORTS_SUCCESS,
    payload: data,
});

export const readQaReportsError = (error) => ({
    type: READ_QA_REPORTS_ERROR,
    payload: error,
});