export const READ_ContentFeedback_BEGIN = 'READ_ContentFeedback_BEGIN';
export const READ_ContentFeedback_SUCCESS = 'READ_ContentFeedback_SUCCESS';
export const READ_ContentFeedback_ERROR = 'READ_ContentFeedback_ERROR';

export const GET_FB_QUESTIONS_BEGIN = 'GET_FB_QUESTIONS_BEGIN';
export const GET_FB_QUESTIONS_SUCCESS = 'GET_FB_QUESTIONS_SUCCESS';
export const GET_FB_QUESTIONS_ERROR = 'GET_FB_QUESTIONS_ERROR';

export const VERIFY_FEEDBACK_BEGIN = 'VERIFY_FEEDBACK_BEGIN';
export const VERIFY_FEEDBACK_SUCCESS = 'VERIFY_FEEDBACK_SUCCESS';
export const VERIFY_FEEDBACK_ERROR = 'VERIFY_FEEDBACK_ERROR';

export const POST_FEEDBACK_BEGIN = 'POST_FEEDBACK_BEGIN';
export const POST_FEEDBACK_SUCCESS = 'POST_FEEDBACK_SUCCESS';
export const POST_FEEDBACK_ERROR = 'POST_FEEDBACK_ERROR';

export const readContentBegin = () => ({
    type: READ_ContentFeedback_BEGIN,
});

export const readContentSuccess = (data) => ({
    type: READ_ContentFeedback_SUCCESS,
    payload: data,
});

export const readContentError = (error) => ({
    type: READ_ContentFeedback_ERROR,
    payload: error,
});

export const getFBQuestionsBegin = () => ({
    type: GET_FB_QUESTIONS_BEGIN,
});

export const getFBQuestionsSuccess = (data) => ({
    type: GET_FB_QUESTIONS_SUCCESS,
    payload: data,
});

export const getFBQuestionsError = (error) => ({
    type: GET_FB_QUESTIONS_ERROR,
    payload: error,
});

export const verifyFeedbackBegin = () => ({
    type: VERIFY_FEEDBACK_BEGIN,
});

export const verifyFeedbackSuccess = (data) => ({
    type: VERIFY_FEEDBACK_SUCCESS,
    payload: data,
});

export const verifyFeedbackError = (error) => ({
    type: VERIFY_FEEDBACK_ERROR,
    payload: error,
});


export const postFeedbackBegin = () => ({
    type: POST_FEEDBACK_BEGIN,
});

export const postFeedbackSuccess = (data) => ({
    type: POST_FEEDBACK_SUCCESS,
    payload: data,
});

export const postFeedbackError = (error) => ({
    type: POST_FEEDBACK_ERROR,
    payload: error,
});
