import { message, notification } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';

const questionGenerationSuccess = () => {
  notification.success({
    message: 'Questions Generated successfully',
  });
};

const addNotificationError = (err) => {
  notification.error({
    message: err,
  });
};


const updateNotificationSuccess = () => {
  notification.success({
    message: 'Question Reviewed Successfully',
  });
};

const updateNotificationError = (err) => {
  notification.error({
    message: err,
  });
};

const deleteNotificationSuccess = () => {
  notification.success({
    message: 'Q & A deleted successfully',
  });
};

const deleteNotificationFailed = () => {
  notification.error({
    message: 'Q & A deletion failed',
  });
};

const {
  generateQuestionsPostBegin,
  generateQuestionsPostSuccess,
  generateQuestionsPostErr,

  generateQuestionsReadBegin,
  generateQuestionsReadSuccess,
  generateQuestionsReadErr,

  QAUpdateBegin,
  QAUpdateSuccess,
  QAUpdateErr,

  getQuestionsBegin,
  getQuestionsSuccess,
  getQuestionsErr,

  deleteQABegin,
  deleteQASuccess,
  deleteQAErr,

  answerEvaluateBegin,
  answerEvaluateSuccess,
  answerEvaluateErr,
  
  getAttemptedQABegin,
  getAttemptedQASuccess,
  getAttemptedQAErr,

} = actions;


const generateQuestionsDataSubmit = (formData) => {
  return async (dispatch) => {
    try {
      await dispatch(generateQuestionsPostBegin());
      const response = await DataService.post('/topicmaster/text-to-QnA-New', formData);
      const token = getItem('access_token');
      const data = response.data      
      if (token && data !== null && data.Error === false) {
        await dispatch(generateQuestionsPostSuccess(data));
        // questionGenerationSuccess()
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(generateQuestionsPostSuccess(null));
          dispatch(logOut())
        }
        else {
          message.error(data.message)
          await dispatch(generateQuestionsPostSuccess(null));
        }
      }
      else {
        await dispatch(generateQuestionsPostErr('Unauthorized access'));
        addNotificationError('Unauthorized access');
      }
    } catch (err) {
      await dispatch(generateQuestionsPostErr(err));
      addNotificationError(err);
    }
  };
};

const generateQuestionsDataRead = (topicId) => {
  return async (dispatch) => {
    try {
      await dispatch(generateQuestionsReadBegin());
      const query = await DataService.get(`/topicmaster/qa/${topicId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(generateQuestionsReadSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(generateQuestionsReadSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(generateQuestionsReadSuccess(null));
        }
      }
      else {
        await dispatch(generateQuestionsReadSuccess(null));
        // await dispatch(generateQuestionsErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(generateQuestionsReadErr(err));
    }
  };
};

const QADataUpdate = (topicId, formData) => {
  return async (dispatch) => {
    try {
      await dispatch(QAUpdateBegin());
      // /topicmaster/updateqa/${topicId}
      const response = await DataService.put(`/qamaster/update`, formData);
      const token = getItem('access_token');
      const data = response.data
      if (token && data !== null && data.Error === false) {
        await dispatch(QAUpdateSuccess(data));
        updateNotificationSuccess();
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(QAUpdateSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(QAUpdateSuccess(null));
        }
      }
      else {
        await dispatch(QAUpdateSuccess(null));
      }
    } catch (err) {
      await dispatch(QAUpdateErr(err));
      updateNotificationError(err);
    }
  };
};


const getQAByTopic = (topicId) => {
  return async (dispatch) => {
    try {
      await dispatch(getQuestionsBegin());
      const query = await DataService.get(`/topicmaster/questions/${topicId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(getQuestionsSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(getQuestionsSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(getQuestionsSuccess(null));
        }
      }
      else {
        await dispatch(getQuestionsSuccess(null));
      }
    } catch (err) {
      await dispatch(getQuestionsErr(err));
    }
  };
};

const deleteQA = (questionId, topicId) => {
  return async (dispatch) => {
    try {
      await dispatch(deleteQABegin());
      const query = await DataService.delete(`/topicmaster/qa/delete/${topicId}/${questionId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token) {
        await dispatch(deleteQASuccess(query.data));
        if (query.data.message === "deleted") {
          deleteNotificationSuccess()
        }
        else if (query.data.message === "error in deleting") {
          deleteNotificationFailed()
        }
        else if (data !== null && data.Error === true) {
          if (Object.keys(data).length >= 3 && data.statusCode == 401) {
            message.error(data.message)
            await dispatch(deleteQASuccess(null));
            dispatch(logOut())
          }
          else {
            await dispatch(deleteQASuccess(null));
          }
        }
      } else {
        await dispatch(deleteQASuccess(null));
        // await dispatch(generateQuizErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(deleteQAErr(err));
    }
  };
}


const EvaluateAnswer = (answers) => {
  return async (dispatch) => {
    try {
      await dispatch(answerEvaluateBegin());
      const query = await DataService.post(`/flask`,answers);
      const token = getItem('access_token');
      const data = query.data      
      if (token && data !== null && data.Error === false) {
        await dispatch(answerEvaluateSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(answerEvaluateSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(answerEvaluateSuccess(null));
        }
      }
      else {
        await dispatch(answerEvaluateSuccess(null));
      }
    } catch (err) {
      await dispatch(answerEvaluateErr(err));
    }
  };
};

// student 
const GetAttemptedQ = (topicId) => {
  return async (dispatch) => {
    try {
      await dispatch(getAttemptedQABegin());
      const query = await DataService.get(`/qa-attempts/evaluation-details/${topicId}`);
      const token = getItem('access_token');
      const data = query.data            
      if (token && data !== null && data.Error === false) {
        await dispatch(getAttemptedQASuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(getAttemptedQASuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(getAttemptedQASuccess(null));
        }
      }
      else {
        await dispatch(getAttemptedQASuccess(null));
      }
    } catch (err) {
      await dispatch(getAttemptedQAErr(err));
    }
  };
}

export {
  generateQuestionsDataRead,
  generateQuestionsDataSubmit,
  QADataUpdate,
  getQAByTopic,
  deleteQA,
  EvaluateAnswer,
  GetAttemptedQ
};
