import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';


const {

  getStudentInterviewsBegin,
  getStudentInterviewsSuccess,
  getStudentInterviewsErr,

  getInterviewAttemptsBegin,
  getInterviewAttemptsSuccess,
  getInterviewAttemptsErr,

} = actions;


const GetStudentInterviews = () => {
  return async (dispatch) => {
    try {
      await dispatch(getStudentInterviewsBegin());


      const role = getItem('role');
      let endpoint;
      if ( role === 'ACADEMICSTUDENT') {
        endpoint = '/interview-master/academicinterviews';
      } else if( role === 'STUDENT') {
        endpoint = '/interview-master/fsinterviews';
      }

      const response = await DataService.get(endpoint);
      const token = getItem('access_token');    
      const data = response.data;

      if (token && data !== null && data.Error === false) {
        await dispatch(getStudentInterviewsSuccess(data.payload));
      } else if (data !== null && data.Error === true) {
        message.error(data.message);
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          await dispatch(getStudentInterviewsSuccess(null));
          dispatch(logOut());
        } else {
          await dispatch(getStudentInterviewsSuccess(null));
        }
      } else {
        await dispatch(getStudentInterviewsSuccess(null));
      }
    } catch (err) {
      await dispatch(getStudentInterviewsErr(err));
    }
  };
};

const getInterviewAttemptsById = (interviewId) => {
  return async (dispatch) => {
    try {
      await dispatch(getInterviewAttemptsBegin());
      
      const role = getItem('role');
      let endpoint;
      if ( role === 'ACADEMICSTUDENT') {
        endpoint = `/interview-attempts/findbyacademicinterviewMr/${interviewId}`;
      } else if(role === 'STUDENT') {
        endpoint = `/interview-attempts/findbyfsinterviewMr/${interviewId}`;
      }

      const response = await DataService.get(endpoint);
      const token = getItem('access_token');
      const data = response.data
      if (token && data !== null && data.Error === false) {
        await dispatch(getInterviewAttemptsSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        message.error(data.message)
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          await dispatch(getInterviewAttemptsSuccess([]));
          dispatch(logOut())
        }
        else {
          await dispatch(getInterviewAttemptsSuccess([]));
        }
      }
      else {
        await dispatch(getInterviewAttemptsSuccess([]));
      }
    } catch (err) {
      await dispatch(getInterviewAttemptsErr(err));
    }
  };
};


export {
  GetStudentInterviews,
  getInterviewAttemptsById,
};
