const actions = {
  LOGIN_BEGIN: 'LOGIN_BEGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERR: 'LOGIN_ERR',

  LOGOUT_BEGIN: 'LOGOUT_BEGIN',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERR: 'LOGOUT_ERR',
  
  FORGOT_PASSWORD_BEGIN: 'FORGOT_PASSWORD_BEGIN',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERR: 'FORGOT_PASSWORD_ERR',
  
  VERIFY_OTP_BEGIN: 'VERIFY_OTP_BEGIN',
  VERIFY_OTP_SUCCESS: 'VERIFY_OTP_SUCCESS',
  VERIFY_OTP_ERR: 'VERIFY_OTP_ERR',
  
  RESET_PASS_BEGIN: 'RESET_PASS_BEGIN',
  RESET_PASS_SUCCESS: 'RESET_PASS_SUCCESS',
  RESET_PASS_ERR: 'RESET_PASS_ERR',
  
  GET_MSG_BEGIN: 'GET_MSG_BEGIN',
  GET_MSG_SUCCESS: 'GET_MSG_SUCCESS',
  GET_MSG_ERR: 'GET_MSG_ERR',
  
  GET_CLG_MSG_BEGIN: 'GET_CLG_MSG_BEGIN',
  GET_CLG_MSG_SUCCESS: 'GET_CLG_MSG_SUCCESS',
  GET_CLG_MSG_ERR: 'GET_CLG_MSG_ERR',

  loginBegin: () => {
    return {
      type: actions.LOGIN_BEGIN,
    };
  },

  loginSuccess: (data) => {
    return {
      type: actions.LOGIN_SUCCESS,
      data,
    };
  },

  loginErr: (err) => {
    return {
      type: actions.LOGIN_ERR,
      err,
    };
  },

  logoutBegin: () => {
    return {
      type: actions.LOGOUT_BEGIN,
    };
  },

  logoutSuccess: (data) => {
    return {
      type: actions.LOGOUT_SUCCESS,
      data,
    };
  },

  logoutErr: (err) => {
    return {
      type: actions.LOGOUT_ERR,
      err,
    };
  },

  forgotPassBegin: () => {
    return {
      type: actions.FORGOT_PASSWORD_BEGIN,
    };
  },

  forgotPassSuccess: (data) => {
    return {
      type: actions.FORGOT_PASSWORD_SUCCESS,
      data,
    };
  },

  forgotPassErr: (err) => {
    return {
      type: actions.FORGOT_PASSWORD_ERR,
      err,
    };
  },
  
  verifyOTPBegin: () => {
    return {
      type: actions.VERIFY_OTP_BEGIN,
    };
  },

  verifyOTPSuccess: (data) => {
    return {
      type: actions.VERIFY_OTP_SUCCESS,
      data,
    };
  },

  verifyOTPErr: (err) => {
    return {
      type: actions.VERIFY_OTP_ERR,
      err,
    };
  },
  
  resetPassBegin: () => {
    return {
      type: actions.RESET_PASS_BEGIN,
    };
  },

  resetPassSuccess: (data) => {
    return {
      type: actions.RESET_PASS_SUCCESS,
      data,
    };
  },

  resetPassErr: (err) => {
    return {
      type: actions.RESET_PASS_ERR,
      err,
    };
  },
  
  getmsgBegin: () => {
    return {
      type: actions.GET_MSG_BEGIN,
    };
  },

  getmsgSuccess: (data) => {
    return {
      type: actions.GET_MSG_SUCCESS,
      data,
    };
  },

  getmsgErr: (err) => {
    return {
      type: actions.GET_MSG_ERR,
      err,
    };
  },
  
  getclgmsgBegin: () => {
    return {
      type: actions.GET_CLG_MSG_BEGIN,
    };
  },

  getclgmsgSuccess: (data) => {
    return {
      type: actions.GET_CLG_MSG_SUCCESS,
      data,
    };
  },

  getclgmsgErr: (err) => {
    return {
      type: actions.GET_CLG_MSG_ERR,
      err,
    };
  },

};

export default actions;
