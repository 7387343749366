const actions = {
    WEEKLY_TOPICS_READ_BEGIN: 'WEEKLY_TOPICS_READ_BEGIN',
    WEEKLY_TOPICS_READ_SUCCESS: 'WEEKLY_TOPICS_READ_SUCCESS',
    WEEKLY_TOPICS_READ_ERR: 'WEEKLY_TOPICS_READ_ERR',

    weeklyTopicsReadBegin: () => {
        return {
          type: actions.WEEKLY_TOPICS_READ_BEGIN,
        };
      },
    
      weeklyTopicsReadSuccess: (data) => {
        return {
          type: actions.WEEKLY_TOPICS_READ_SUCCESS,
          data,
        };
      },
    
      weeklyTopicsReadErr: (err) => {
        return {
          type: actions.WEEKLY_TOPICS_READ_ERR,
          err,
        };
    },
}
export default actions;