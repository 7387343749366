import actions from './actions';
import { getItem } from '../../utility/localStorageControl';

const {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_ERR,
  LOGOUT_BEGIN,
  LOGOUT_SUCCESS,
  LOGOUT_ERR,
  FORGOT_PASSWORD_BEGIN,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERR,
  VERIFY_OTP_BEGIN,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_ERR,
  RESET_PASS_BEGIN,
  RESET_PASS_SUCCESS,
  RESET_PASS_ERR,
  GET_MSG_BEGIN,
  GET_MSG_SUCCESS,
  GET_MSG_ERR,
  GET_CLG_MSG_BEGIN,
  GET_CLG_MSG_SUCCESS,
  GET_CLG_MSG_ERR
} = actions;

const initState = {
  login: getItem('logedIn'),
  loading: false,
  role: getItem('role'),
  username: getItem('username'),
  error: null,
};

const forgotPassInitState = {
  data: null,
  loading: false,
  error: null,
};

const verifyOTPState = {
  data: null,
  loading: false,
  error: null,
};

const resetpassRes = {
  data: null,
  loading: false,
  error: null,
};

const messageInitialState = {
  data: null,
  loading: false,
  error: null,
};

const collegeMessageInitialState = {
  data: null,
  loading: false,
  error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case LOGIN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case LOGOUT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const ForgotPasswordReducer = (state = forgotPassInitState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FORGOT_PASSWORD_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
        err: null
      };
    case FORGOT_PASSWORD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const VerifyOTPReducer = (state = verifyOTPState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case VERIFY_OTP_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
        err: null,
      };
    case VERIFY_OTP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const ResetPassReducer = (state = resetpassRes, action) => {
  const { type, data, err } = action;
  switch (type) {
    case RESET_PASS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case RESET_PASS_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
        err: null,
      };
    case RESET_PASS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const MessageReducer = (state = messageInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_MSG_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_MSG_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
        err: null,
      };
    case GET_MSG_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const CollegeWiseMessageReducer = (state = collegeMessageInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_CLG_MSG_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_CLG_MSG_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
        err: null,
      };
    case GET_CLG_MSG_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export { AuthReducer, ForgotPasswordReducer, VerifyOTPReducer, ResetPassReducer, MessageReducer, CollegeWiseMessageReducer };