import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Dashboard from '../../container/teacher/Dashboard';
import Subject from './subject';
import withTeacherLayout from '../../layout/withTeacherLayout';
import CustomSpinner from '../../container/ui-elements/spinner/CustomSpinner';
import Monitor from '../../container/teacher/Monitor';
import ChangePassword from '../../container/ChangePassword';
import Profile from '../../container/teacher/Profile';
import Answers from '../../container/teacher/Answers';
import AssessmentLogins from '../../container/teacher/AssessmentLogins';
import Transcribe from '../../container/teacher/Transcribe';
import DrawingTool from '../../container/teacher/DrawingTool';
import LockUnlockTopics from '../../container/teacher/LockUnlockTopics';
import QaReports from '../../container/teacher/QaReports';

const NotFound = lazy(() => import('../../container/pages/404'));

const Teacher = React.memo(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Suspense
      fallback={
        <div className="">
          <CustomSpinner />
        </div>
      }
    >
      <Routes>
        <Route index path="/" element={<Dashboard />} />   
        {/* <Route index path="/change-password" element={<ChangePassword />} />    */}
        <Route path="/teacher-profile/*" element={<Profile/>} />   
        <Route path="/subjects/*" element={<Subject />} />                                      
        <Route path="/monitor-students/*" element={<Monitor />} />     
        <Route path="/answers" element={<Answers/>}/>
        <Route path="/qa-reports" element={<QaReports/>}/>
        <Route path="/assessment" element={<AssessmentLogins/>}/>
        <Route path="/lock-unlock-assessment-topics" element={<LockUnlockTopics/>}/>
        <Route path="/transcribe" element={<Transcribe/>}/>
        {/* <Route path="/drawing-tool" element={<DrawingTool/>}/> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default withTeacherLayout(Teacher);
