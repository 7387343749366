import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { message } from 'antd';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';

const {
  getLeaderboardBegin,
  getLeaderboardSuccess,
  getLeaderboardErr,
} = actions;

const ReadLeaderboardData = () => {
  return async (dispatch) => {
    try {
      await dispatch(getLeaderboardBegin());
      const query = await DataService.get('/usermaster/learningleaderboard');
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(getLeaderboardSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(getLeaderboardSuccess([]));
          dispatch(logOut())
        } else {
          await dispatch(getLeaderboardSuccess([]));
        }
      }
      else {
        await dispatch(getLeaderboardSuccess([]));
      }
    } catch (err) {
      await dispatch(getLeaderboardErr(err));
    }
  };
};
export {
  ReadLeaderboardData,
};
