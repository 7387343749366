import actions from './actions';

const {
  GENERATE_QUIZ_ADD_BEGIN,
  GENERATE_QUIZ_ADD_SUCCESS,
  GENERATE_QUIZ_ADD_ERR,

  QUIZ_UPDATE_BEGIN,
  QUIZ_UPDATE_SUCCESS,
  QUIZ_UPDATE_ERR,
  
  READ_QUIZ_BEGIN,
  READ_QUIZ_SUCCESS,
  READ_QUIZ_ERR,

  DELETE_QUIZ_BEGIN,
  DELETE_QUIZ_SUCCESS,
  DELETE_QUIZ_ERR,
  
  GET_MCQS_BEGIN,
  GET_MCQS_SUCCESS,
  GET_MCQS_ERR

} = actions;

const postResponseInitialState = {
  data: null,    
  loading: false,
  error: null,
};

const QUIZUpdateInitialState = {
  data:null,
  loading:false,
  error:null,
}

const getAllQUIZInitialState = {
  data:null,
  loading:false,
  error:null,
}

const delQuizInitialState = {
  data:null,
  loading:false,
  error:null,
}

const getAllQUIZByTopicInitialState = {
  data:null,
  loading:false,
  error:null,
}

const postQuizResponseReducer = (state = postResponseInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    
    case GENERATE_QUIZ_ADD_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GENERATE_QUIZ_ADD_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GENERATE_QUIZ_ADD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const UpdateQuizReducer = (state = QUIZUpdateInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case QUIZ_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case QUIZ_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case QUIZ_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const ReadAllQuizReducer = (state = getAllQUIZInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case READ_QUIZ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case READ_QUIZ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case READ_QUIZ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const DeleteQuizReducer = (state = delQuizInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case DELETE_QUIZ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case DELETE_QUIZ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case DELETE_QUIZ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const ReadAllQuizByTopicReducer = (state = getAllQUIZByTopicInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_MCQS_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_MCQS_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GET_MCQS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { postQuizResponseReducer, UpdateQuizReducer, ReadAllQuizByTopicReducer, DeleteQuizReducer, ReadAllQuizReducer };
