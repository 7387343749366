import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { getItem } from '../../utility/localStorageControl';
import { message, notification } from 'antd';
import { logOut } from '../authentication/actionCreator';

const {
  getAssessmentLoginsBegin,
  getAssessmentLoginsSuccess,
  getAssessmentLoginsErr,
  updateStudentFlagBegin,
  updateStudentFlagSuccess,
  updateStudentFlagErr
} = actions;

const GetAssessmentLogins = () => {
  return async (dispatch) => {
    try {
      await dispatch(getAssessmentLoginsBegin());
      const query = await DataService.get('/ac/params');
      const token = getItem('access_token');
      const data = query.data
      if (token && data.Error === false) {
        await dispatch(getAssessmentLoginsSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(getAssessmentLoginsSuccess([]));
          dispatch(logOut())
        }
        else {
          await dispatch(getAssessmentLoginsSuccess([]));
        }
      }
      else {
        await dispatch(getAssessmentLoginsSuccess([]));
      }
    } catch (err) {
      await dispatch(getAssessmentLoginsErr(err));
    }
  };
};

const UpdateStudentFlag = (acids) => {
  return async (dispatch) => {
    try {
      await dispatch(updateStudentFlagBegin());
      const query = await DataService.put(`/ac/update`,acids);
      const token = getItem('access_token');
      const data = query.data
      if (token && data.Error === false) {
        message.success(data.message)
        await dispatch(updateStudentFlagSuccess(data));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(updateStudentFlagSuccess(null));
          dispatch(logOut())
        }
        else {
          message.error(data.message)
          await dispatch(updateStudentFlagSuccess(null));
        }
      }
      else {
        await dispatch(updateStudentFlagSuccess(null));
      }
    } catch (err) {
      await dispatch(updateStudentFlagErr(err));
    }
  };
};


export {
  GetAssessmentLogins,
  UpdateStudentFlag
};
