import React, { Suspense, lazy } from 'react'
import { Main } from '../styled'
import { Col, Row, Skeleton } from 'antd'
import { Cards } from '../../components/cards/frame/cards-frame';
import { SettingWrapper } from '../profile/settings/overview/style';
import { Navigate, Route, Routes } from 'react-router-dom';
import Password from '../profile/settings/overview/Passwoard';
import { PageHeader } from '../../components/page-headers/page-headers';

const AuthorBox = lazy(() => import('../profile/settings/overview/TeacherAuthorBox'));

export default function Profile() {
    return (
        <div>
            <Main>
                <PageHeader className="ninjadash-page-header-main" title="Profile" />
                <Row gutter={25}>
                    <Col xxl={5} lg={8} md={10} xs={24}>
                        <Suspense
                            fallback={
                                <Cards headless>
                                    <Skeleton avatar />
                                </Cards>
                            }
                        >
                            <AuthorBox />
                        </Suspense>
                    </Col>
                    <Col xxl={19} lg={16} md={14} xs={24}>
                        <SettingWrapper>
                            <Suspense
                                fallback={
                                    <Cards headless>
                                        <Skeleton paragraph={{ rows: 20 }} />
                                    </Cards>
                                }
                            >
                                <Routes>
                                    <Route path='/' element={<Navigate to='change-password' />} />
                                    {/* <Route path="leaderboard" element={<Leaderboard />} /> */}
                                    <Route path="change-password" element={<Password />} />
                                </Routes>
                            </Suspense>
                        </SettingWrapper>
                    </Col>
                </Row>
            </Main>
        </div>
    )
}
