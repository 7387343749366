import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { message } from 'antd';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';

const {

  createTopicBegin,
  createTopicSuccess,
  createTopicErr,

  updateTopicBegin,
  updateTopicSuccess,
  updateTopicErr,

  topicSingleReadBegin,
  topicSingleReadSuccess,
  topicSingleReadErr,

  topicStatusUpdateBegin,
  topicStatusUpdateSuccess,
  topicStatusUpdateErr,

  topicsReadBegin,
  topicsReadSuccess,
  topicsReadErr,

  clearTopicBegin,
  clearTopicSuccess,
  clearTopicErr,

  studentTopicsReadBegin,
  studentTopicsReadSuccess,
  studentTopicsReadErr

} = actions;


const CreateNewTopic = (formData) => {
  return async (dispatch) => {
    try {
      await dispatch(createTopicBegin());
      const query = await DataService.post(`/topicmaster/create`, formData);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        message.success(data.message)
        await dispatch(createTopicSuccess(data));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(createTopicSuccess(null));
          dispatch(logOut())
        }
        else if (data !== null && data.Error === true) {
          message.error(data.message)
          await dispatch(createTopicSuccess(null));
        }
      }
      else {
        await dispatch(createTopicSuccess(null));
      }
    } catch (err) {
      await dispatch(createTopicErr(err));
    }
  };
};

const UpdateTopicDetails = (formData) => {
  return async (dispatch) => {
    try {
      await dispatch(updateTopicBegin());
      const query = await DataService.put(`/topicmaster/updatecreate`, formData);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        message.success(data.message)
        await dispatch(updateTopicSuccess(data));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(updateTopicSuccess(null));
          dispatch(logOut())
        }
        else if (data !== null && data.Error === true) {
          message.error(data.message)
          await dispatch(updateTopicSuccess(null));
        }
      }
      else {
        await dispatch(updateTopicSuccess(null));
      }
    } catch (err) {
      await dispatch(updateTopicErr(err));
    }
  };
};

const getTopicById = (topicId) => {
  return async (dispatch) => {
    try {
      await dispatch(topicSingleReadBegin());
      const query = await DataService.get(`/topicmaster/params/${topicId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(topicSingleReadSuccess(data.payload));
      } else {
        await dispatch(topicSingleReadSuccess(null));
      }
    } catch (err) {
      await dispatch(topicSingleReadErr(err));
    }
  };
};


const updateTopicStatusById = (formData) => {
  return async (dispatch) => {
    try {
      await dispatch(topicStatusUpdateBegin());
      const query = await DataService.put(`/topicmaster/status`, formData);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(topicStatusUpdateSuccess(data));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(topicStatusUpdateSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(topicStatusUpdateSuccess(null));
        }
      }
      else {
        await dispatch(topicStatusUpdateSuccess(null));
      }
    } catch (err) {
      await dispatch(topicStatusUpdateErr(err));
    }
  };
};

// not in use
const getTopicsByUnitId = (unitId) => {
  return async (dispatch) => {
    try {
      await dispatch(topicsReadBegin());
      const query = await DataService.get(`/studentmaster/get-topics-unit/${unitId}`);
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(topicsReadSuccess(query.data.payload));
      } else {
        await dispatch(topicsReadSuccess(null));
      }
    } catch (err) {
      await dispatch(topicsReadErr(err));
    }
  };
};

const getStudentTopicsByUnitId = (unitId) => {
  return async (dispatch) => {
    try {      
      await dispatch(studentTopicsReadBegin());
      const query = await DataService.get(`/unitmaster/topicdetails/${unitId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(studentTopicsReadSuccess(query.data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(studentTopicsReadSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(studentTopicsReadSuccess(null));
        }
      }
      else {
        await dispatch(studentTopicsReadSuccess(null));
      }
    } catch (err) {
      await dispatch(studentTopicsReadErr(err));
    }
  };
};

const clearTopicByTopicId = (topicId) => {
  return async (dispatch) => {
    try {
      await dispatch(clearTopicBegin());
      const query = await DataService.delete(`/topicmaster/topic/${topicId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(clearTopicSuccess(data));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(clearTopicSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(clearTopicSuccess(null));
        }
      }
      else {
        await dispatch(clearTopicSuccess(null));
      }
    } catch (err) {
      await dispatch(clearTopicErr(err));
    }
  };
};


export {
  CreateNewTopic,
  UpdateTopicDetails,
  getTopicById,
  updateTopicStatusById,
  getTopicsByUnitId,
  clearTopicByTopicId,
  getStudentTopicsByUnitId,
};
