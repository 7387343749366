import React from 'react';
import { NavLink } from 'react-router-dom';
import { TopMenuStyle } from './Style';


function AcademicStudentTopMenu() {
  const path = '/ac_student';      

  return (
    <TopMenuStyle>
      <div className="ninjadash-top-menu">
        <ul>
          <li className="">
            <NavLink to={`${path}`} >
              Interviews
            </NavLink>
          </li>
        </ul>
      </div>
    </TopMenuStyle>
  );
}

export default AcademicStudentTopMenu;