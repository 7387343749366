import { ConfigProvider, Spin } from 'antd';
import 'antd/dist/antd.less';
import React, { useEffect, useState, lazy } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import SimpleReactLightbox from 'simple-react-lightbox';
import { ThemeProvider } from 'styled-components';
import ProtectedRoute from './components/utilities/protectedRoute';
import config from './config/config';
import store, { rrfProps } from './redux/store';

import Teacher from './routes/teacher';
import Student from './routes/student';
import Auth from './routes/auth';
import './static/css/style.css';
import CustomSpinner from './container/ui-elements/spinner/CustomSpinner';
import { getItem } from './utility/localStorageControl';
import { getMessage } from './redux/authentication/actionCreator';
import academic_student from './routes/academic_student';

const NotFound = lazy(() => import('./container/pages/404'));

const { themeColor } = config;

function ProviderConfig() {
  const { rtl, isLoggedIn, topMenu, mainContent, auth, isLoading } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      mainContent: state.ChangeLayoutMode.mode,
      isLoggedIn: state.auth.login,
      isLoading: state.auth.loading,
      auth: state.auth,
    };
  });

  const dispatch = useDispatch()
  const [role, setRole] = useState("");

  useEffect(() => {
    const role = getItem('role')
    setRole(role)
  })


  const { msg, messageLoading } = useSelector((state) => {
    return {
      msg: state.msg.data,
      messageLoading: state.msg.loading
    };
  })

  useEffect(() => {
    dispatch(getMessage())
  }, [])

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...themeColor, rtl, topMenu, mainContent }}>
        {isLoading ? (
          <div className="">
            <CustomSpinner Message={'Logging in... Please wait.'} />
          </div>
        ) : (
          <SimpleReactLightbox>
            <Router basename={''}>
              {!isLoggedIn ? (
                <div>
                  <div className='text-center' dangerouslySetInnerHTML={{ __html: msg }} />
                  <Routes>
                    <Route path="/*" element={<Auth />} />
                  </Routes>
                </div>
              ) : (
                isLoggedIn && role === 'TEACHER' ?
                  <Routes>
                    <Route path="/teacher/*" element={<ProtectedRoute path="/*" Component={Teacher} />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                  :
                  isLoggedIn && role === 'STUDENT' ?
                    <Routes>
                      <Route path="/student/*" element={<ProtectedRoute path="/*" Component={Student} />} />
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                    :
                    isLoggedIn && role === 'ACADEMICSTUDENT' &&
                    <Routes>
                      <Route path="/ac_student/*" element={<ProtectedRoute path="/*" Component={academic_student} />} />
                      <Route path="*" element={<NotFound />} />
                    </Routes>
              )
              }
              {isLoggedIn && role === 'TEACHER' && (
                <Routes>
                  <Route path="/" element={<Navigate to="/teacher" />} />
                </Routes>
              )}
              {isLoggedIn && role === 'STUDENT' && (
                <Routes>
                  <Route path="/" element={<Navigate to="/student" />} />
                </Routes>
              )}
              {isLoggedIn && role === 'ACADEMICSTUDENT' && (
                <Routes>
                  <Route path="/" element={<Navigate to="/ac_student" />} />
                </Routes>
              )}
            </Router>
          </SimpleReactLightbox>
        )}
      </ThemeProvider>
    </ConfigProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default App;
