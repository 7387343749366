export const GET_TEACHER_MONITOR_BEGIN = 'GET_TEACHER_MONITOR_BEGIN';
export const GET_TEACHER_MONITOR_SUCCESS = 'GET_TEACHER_MONITOR_SUCCESS';
export const GET_TEACHER_MONITOR_ERROR = 'GET_TEACHER_MONITOR_ERROR';

export const readTeacherMonitorBegin = () => ({
    type: GET_TEACHER_MONITOR_BEGIN,
});

export const readTeacherMonitorSuccess = (data) => ({
    type: GET_TEACHER_MONITOR_SUCCESS,
    payload: data,
});

export const readTeacherMonitorError = (error) => ({
    type: GET_TEACHER_MONITOR_ERROR,
    payload: error,
});
