import actions from './actions';

const {
  READ_CHUNK_BEGIN,
  READ_CHUNK_SUCCESS,
  READ_CHUNK_ERR,
  UPDATE_CHUNK_BEGIN,
  UPDATE_CHUNK_SUCCESS,
  UPDATE_CHUNK_ERR,
  DELETE_CHUNK_BEGIN,
  DELETE_CHUNK_SUCCESS,
  DELETE_CHUNK_ERR,
} = actions;

const initialState = {
  data: null,
  url: null,  
  loading: false,
  error: null,
};

const updateInitialState = {
  data: null,
  url: null,  
  loading: false,
  error: null,
};

const deleteInitialState = {
  data: null,
  url: null,  
  loading: false,
  error: null,
};

const ReviewContentReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case READ_CHUNK_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case READ_CHUNK_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case READ_CHUNK_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };      
      
    default:
      return state;
  }
};


const ReviewChunkUpdateReducer = (state = updateInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {

      case UPDATE_CHUNK_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_CHUNK_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case UPDATE_CHUNK_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
      
    default:
      return state;
  }
};

const DeleteChunkReducer = (state = deleteInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {

      case DELETE_CHUNK_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case DELETE_CHUNK_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case DELETE_CHUNK_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
      
    default:
      return state;
  }
};

export { ReviewContentReducer, ReviewChunkUpdateReducer, DeleteChunkReducer };
