const actions = {

  GET_SECTIONS_BEGIN: 'GET_SECTIONS_BEGIN',
  GET_SECTIONS_SUCCESS: 'GET_SECTIONS_SUCCESS',
  GET_SECTIONS_ERR: 'GET_SECTIONS_ERR',


  getSectionBegin: () => {
    return {
      type: actions.GET_SECTIONS_BEGIN,
    };
  },

  getSectionSuccess: (data) => {
    return {
      type: actions.GET_SECTIONS_SUCCESS,
      data,
    };
  },

  getSectionErr: (err) => {
    return {
      type: actions.GET_SECTIONS_ERR,
      err,
    };
  },
};

export default actions;
