import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { getItem } from '../../utility/localStorageControl';

const TopicView = lazy(() => import('../../container/teacher/TopicView'))
const NotFound = lazy(() => import('../../container/pages/404'));


function SubjectRoutes() {
  const activeTopicIndex = getItem('activetopic')
  return (
    <Routes>
      <Route path="/subject/:subjectId/unit/:unitId/*" element={<TopicView activeTopicIndex={activeTopicIndex} />} />
      {/* <Route path="/subject/:subjectId/unit/:unitId/topic/:topicId/*" element={<TopicRoutes />} />            */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default SubjectRoutes;