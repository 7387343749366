import { themeColor } from './theme/themeVariables';
import { getItem } from '../utility/localStorageControl';

const mode = getItem('mode')
const config = {
  topMenu: false,  
  rtl: false,
  mainTemplate: mode !== undefined ? mode : 'lightMode',
  themeColor,
};

export default config;
