import React, { useEffect, useState } from 'react'
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Col, Form, Input, Row, Select, Table, Tooltip, message } from 'antd';
import { Button } from '../../components/buttons/buttons';
import UilSearch from '@iconscout/react-unicons/icons/uil-search';
import { DataTableStyleWrap } from '../../components/table/Style';
import { useDispatch, useSelector } from 'react-redux';
import { getTeacherMonitorData } from '../../redux/teacher-monitor/actionCreator'
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import topicReadBadge from '../../static/img/TOPIC-READ/TOPIC-READ-24X24.png'
import topicCompleteBadge from '../../static/img/TOPIC-COMPLETE/TOPIC-COMPLETE-24X24.png'
import { readTeacherMonitorSuccess } from '../../redux/teacher-monitor/actions'
import { CSVLink } from 'react-csv';


function Monitor() {

    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const [subjectId, setSubjectId] = useState(0)
    const [weeekId, setWeekId] = useState(0)
    const [sectionId, setSectionId] = useState(0)        

    const { TeacherMonitor, TeacherMonitorLoading, SubjectsUnits, SubjectsUnitsLoading, Weeks, weeksLoading, Sections, SectionsLoading, RegYrsem, RegYrsemLoading } = useSelector((state) => {
        return {
            TeacherMonitor: state.TeacherMonitor.data,
            TeacherMonitorLoading: state.TeacherMonitor.loading,
            SubjectsUnits: state.SubjectsUnits.data,
            SubjectsUnitsLoading: state.SubjectsUnits.loading,
            Weeks: state.Weeks.data,
            weeksLoading: state.Weeks.loading,
            Sections: state.Sections.data,
            SectionsLoading: state.Sections.loading,
            RegYrsem: state.RegYrsem.data,
            RegYrsemLoading: state.RegYrsem.loading,
        }
    })

    useEffect(() => {
        dispatch(readTeacherMonitorSuccess([]))
    }, [])

    const columnsSort = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
        },
        {
            title: 'Roll No',
            dataIndex: 'rollno',
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.rollno > b.rollno ? 1 : -1,
            sortDirections: ['ascend'],
        },
        {
            title: 'Name',
            dataIndex: 'student_name',
            // filters: [
            //     {
            //         text: 'Joe',
            //         value: 'Joe',
            //     },
            //     {
            //         text: 'Jim',
            //         value: 'Jim',
            //     },
            //     {
            //         text: 'Submenu',
            //         value: 'Submenu',
            //         children: [
            //             {
            //                 text: 'Green',
            //                 value: 'Green',
            //             },
            //             {
            //                 text: 'Black',
            //                 value: 'Black',
            //             },
            //         ],
            //     },
            // ],
            // specify the condition of filtering result
            // here is that finding the name started with `value`
            onFilter: (value, record) => record.student_name.indexOf(value) === 0,
            sorter: (a, b) => a.student_name.length - b.student_name.length,
            sortDirections: ['descend'],
        },
        {
            title: 'Section',
            dataIndex: 'section_name',
        },
        {
            title: <Tooltip title='MCQS Badge'><img src={topicReadBadge} width={16} /></Tooltip>,
            dataIndex: 'readBadgeCount',
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.readBadgeCount - b.readBadgeCount,
        },
        {
            title: <Tooltip title='Assessment Badge'><img src={topicCompleteBadge} width={16} /></Tooltip>,
            dataIndex: 'assesmentBadgeCount',
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.assesmentBadgeCount - b.assesmentBadgeCount,
        },
        // {
        //     title: 'Total Topics',
        //     dataIndex: 'WeekTopicCount',
        //     // defaultSortOrder: 'descend',
        //     sorter: (a, b) => a.WeekTopicCount - b.WeekTopicCount,
        // },
    ];


    const [tableData, setTableData] = useState([])
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        let data = []
        TeacherMonitor.length !== 0 && TeacherMonitor.map((obj, index) => {
            const { student_id, rollno, student_name, branch_id, branch, branch_code, section_name, subject_id, subject_name, unit_id, teacher_name, readBadgeCount, assesmentBadgeCount, weekTopicCount } = obj
            data.push({
                key: index,
                "sno": index + 1,
                student_id,
                rollno,
                student_name,
                branch_id,
                branch,
                branch_code,
                subject_id,
                subject_name,                                
                unit_id,
                teacher_name,
                readBadgeCount,
                assesmentBadgeCount,
                weekTopicCount,
                section_name
            })
        })
        setTableData(data)
        setFilteredData(data)
    }, [TeacherMonitor])

    const loadStudentsData = () => {
        if (subjectId !== 0 && sectionId !== 0) {
            dispatch(getTeacherMonitorData(subjectId, sectionId))
        }
        else {
            message.warn("Select all filters!!")
        }
    };

    const searchkeys = ['rollno', 'student_name']
    const searchStudent = (e) => {
        const { value } = e.currentTarget;
        const searchData = tableData.length !== 0 && tableData.filter((obj) => searchkeys.some((key) => obj[key].toLowerCase().includes(value.toLowerCase())))
        setFilteredData(searchData)
    };


    //export
    const headers = [
        { label: "S.No", key: "sno" },
        { label: "RollNo", key: "rollno" },
        { label: "StudentName", key: "student_name" },
        { label: "Section", key: "section_name" },
        { label: "SubjectName", key: "subject_name" },        
        { label: "WeekTopicCount", key: "weekTopicCount" },
        { label: "ReadBadgeCount", key: "readBadgeCount" },
        { label: "AssesmentBadgeCount", key: "assesmentBadgeCount" },
    ];

    const csvReport = {
        filename: "Student_Badges_List.csv",
        headers: headers,
        data: filteredData
    }

    return (
        <div>
            <Main className='pt-20'>
                {/* <PageHeader className="ninjadash-page-header-main p-0" title="Monitor" /> */}
                <Cards title="Activity Monitor">
                    <DataTableStyleWrap>
                        <div className="ninjadash-datatable-filter">
                            <div className="ninjadash-datatable-filter__left">
                                <div className="ninjadash-datatable-filter__input">
                                    <span className="label">Subject:</span>
                                    <Select id="monitor_select-subject" style={{ width: 200 }} className="ninjadash-data-status" placeholder="select" onChange={(e) => { setSubjectId(e); setWeekId(0); setSectionId(0) }} loading={SubjectsUnitsLoading}>
                                        {
                                            SubjectsUnits !== null && SubjectsUnits.subjects.length !== 0 && SubjectsUnits.subjects.sort((a, b) => a.name > b.name ? 1 : -1).map((subjectObj, subjectIndex) =>
                                                <Select.Option value={subjectObj.id} key={subjectIndex}>{subjectObj.name}</Select.Option>
                                            )
                                        }
                                    </Select>
                                </div>                                                                
                                <div className="ninjadash-datatable-filter__input">
                                    <span className="label">Week:</span>
                                    <Select id="monitor_select-week" style={{ width: 150 }} className="ninjadash-data-status" placeholder="select" value={weeekId} onChange={(e) => { setWeekId(e); setSectionId(0) }} loading={weeksLoading}>
                                        <Select.Option value={0} key={'selectweek'} >Select</Select.Option>
                                        {
                                            Weeks.length !== 0 && Weeks.map((week, weekIndex) =>
                                                <Select.Option value={week.id} key={weekIndex}>{week.name}</Select.Option>
                                            )
                                        }
                                    </Select>
                                </div>
                                <div className="ninjadash-datatable-filter__input">
                                    <span className="label">Section:</span>
                                    <Select id="monitor_select-section" style={{ width: 160 }} className="ninjadash-data-status" placeholder="select" value={sectionId} onChange={(e) => setSectionId(e)} loading={SectionsLoading}>
                                        <Select.Option value={0} key={'selectsection'} >Select</Select.Option>
                                        {
                                            Sections.length !== 0 && Sections.sort((a, b) => a.name > b.name ? 1 : -1).map((section, sectionIndex) =>
                                                <Select.Option value={section.id} key={sectionIndex}>{section.name}</Select.Option>
                                            )
                                        }
                                    </Select>
                                </div>
                                <div className="ninjadash-datatable-filter__action">
                                    <Button id="monitor_get-button" type="primary" size="small" onClick={() => { loadStudentsData() }}>
                                        Get
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {
                            TeacherMonitorLoading ?
                                <div style={{ width: '100%', height: '400px' }}>
                                    <div className="custom-spin">
                                        <CustomSpinner />
                                    </div>
                                </div>
                                :
                                <div>
                                    <div className='d-flex justify-content-between mb-15 p-0 align-center-v'>
                                        {
                                            TeacherMonitor.length !== 0 &&
                                            <p className='m-0'>
                                                Week Topics: {TeacherMonitor[0].weekTopicCount}
                                            </p>
                                        }
                                        <div className="d-flex align-center-v" style={{ marginLeft: 'auto' }}>
                                            <CSVLink {...csvReport}>
                                                <Button id="monitor_export-button" type="primary" size="small" className="mr-10" disabled={filteredData.length !== 0 ? false : true}>
                                                    Export
                                                </Button>
                                            </CSVLink>
                                            <Input id="monitor_search-input" className='' onChange={searchStudent} size="default" placeholder="Rollno. or Name" prefix={<UilSearch />} />
                                        </div>
                                    </div>
                                    <Table
                                        className="table-responsive"
                                        pagination={{
                                            defaultPageSize: 15,
                                            total: filteredData.length,
                                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                        }}
                                        columns={columnsSort}
                                        dataSource={filteredData}
                                        tableLayout='auto'
                                        size='middle'
                                    />
                                </div>
                        }
                    </DataTableStyleWrap>
                </Cards>
            </Main>
        </div >
    )
}

export default Monitor