const actions = {
  SUBMIT_ALL_QUIZ_BEGIN: 'SUBMIT_ALL_QUIZ_BEGIN',
  SUBMIT_ALL_QUIZ_SUCCESS: 'SUBMIT_ALL_QUIZ_SUCCESS',
  SUBMIT_ALL_QUIZ_ERR: 'SUBMIT_ALL_QUIZ_ERR',

  SAVE_QUIZ_BEGIN: 'SAVE_QUIZ_BEGIN',
  SAVE_QUIZ_SUCCESS: 'SAVE_QUIZ_SUCCESS',
  SAVE_QUIZ_ERR: 'SAVE_QUIZ_ERR',
  
  GET_QUIZ_BEGIN : 'GET_QUIZ_BEGIN',
  GET_QUIZ_SUCCESS : 'GET_QUIZ_SUCCESS',
  GET_QUIZ_ERR : 'GET_QUIZ_ERR',
  
  GET_QUIZ_RESULT_BEGIN : 'GET_QUIZ_RESULT_BEGIN',
  GET_QUIZ_RESULT_SUCCESS : 'GET_QUIZ_RESULT_SUCCESS',
  GET_QUIZ_RESULT_ERR : 'GET_QUIZ_RESULT_ERR',

  submitQuizBegin: () => {
    return {
      type: actions.SUBMIT_ALL_QUIZ_BEGIN,
    };
  },

  submitQuizSuccess: (data) => {
    return {
      type: actions.SUBMIT_ALL_QUIZ_SUCCESS,
      data,
    };
  },

  submitQuizErr: (err) => {
    return {
      type: actions.SUBMIT_ALL_QUIZ_ERR,
      err,
    };
  },

  SaveQuizBegin: () => {
    return {
      type: actions.SAVE_QUIZ_BEGIN,
    };
  },

  SaveQuizSuccess: (data) => {
    return {
      type: actions.SAVE_QUIZ_SUCCESS,
      data,
    };
  },

  SaveQuizErr: (err) => {
    return {
      type: actions.SAVE_QUIZ_ERR,
      err,
    };
  },
 
  getQuizBegin: () => {
    return {
      type: actions.GET_QUIZ_BEGIN,
    };
  },

  getQuizSuccess: (data) => {
    return {
      type: actions.GET_QUIZ_SUCCESS,
      data,
    };
  },

  getQuizErr: (err) => {
    return {
      type: actions.GET_QUIZ_ERR,
      err,
    };
  },

  getQuizResultBegin: () => {
    return {
      type: actions.GET_QUIZ_RESULT_BEGIN,
    };
  },

  getQuizResultSuccess: (data) => {
    return {
      type: actions.GET_QUIZ_RESULT_SUCCESS,
      data,
    };
  },

  getQuizResultErr: (err) => {
    return {
      type: actions.GET_QUIZ_RESULT_ERR,
      err,
    };
  },

};

export default actions;
