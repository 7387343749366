import actions from './actions';

const {
  GET_ASSESSMENT_LOGINS_BEGIN,
  GET_ASSESSMENT_LOGINS_SUCCESS,
  GET_ASSESSMENT_LOGINS_ERR,
  
  UPDATE_STUDENT_FLAG_BEGIN,
  UPDATE_STUDENT_FLAG_SUCCESS,
  UPDATE_STUDENT_FLAG_ERR
} = actions;

const InitialState = {
  data: [],    
  loading: false,
  error: null,
};

const updateInitialState = {
  data: null,    
  loading: false,
  error: null,
};

const GetAssessmentLoginsReducer = (state = InitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    
    case GET_ASSESSMENT_LOGINS_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_ASSESSMENT_LOGINS_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GET_ASSESSMENT_LOGINS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const updateStudentFlagReducer = (state = updateInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    
    case UPDATE_STUDENT_FLAG_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_STUDENT_FLAG_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case UPDATE_STUDENT_FLAG_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { GetAssessmentLoginsReducer, updateStudentFlagReducer };
