const actions = {
  
  GET_NGROK_URL_BEGIN: 'GET_NGROK_URL_BEGIN',
  GET_NGROK_URL_SUCCESS: 'GET_NGROK_URL_SUCCESS',
  GET_NGROK_URL_ERR: 'GET_NGROK_URL_ERR',

  getNgrokURLBegin: () => {
    return {
      type: actions.GET_NGROK_URL_BEGIN,
    };
  },

  getNgrokURLSuccess: (data) => {
    return {
      type: actions.GET_NGROK_URL_SUCCESS,
      data,
    };
  },

  getNgrokURLErr: (err) => {
    return {
      type: actions.GET_NGROK_URL_ERR,
      err,
    };
  },

};

export default actions;
