import {
  UilCreateDashboard,
  UilBooks,
  UilLifeRing
} from '@iconscout/react-unicons';
import { Menu } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';
import { NavTitle } from './Style';
import { studentSubjectsDataRead } from '../redux/studentSubjectsUnits/actionCreator';

function MenuItems({ toggleCollapsed }) {

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const dispatch = useDispatch();

  const path = '/student';

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  useEffect(() => {
    dispatch(studentSubjectsDataRead());
  }, [dispatch]);

  const { StudentSubjects } = useSelector((state) => {
    return {
      StudentSubjects: state.StudentSubjects.data,
      isLoading: state.StudentSubjects.loading,
    };
  });


  const subjects = []

  StudentSubjects.length !== 0 && StudentSubjects.sort((a, b) => a.name > b.name ? 1 : -1).map((subjObj, index) =>
    subjects.push(
      getItem(
        <NavLink
          to={`${path}/subject/${subjObj.id}`}
          onClick={toggleCollapsed}
        >
          {subjObj.name}
        </NavLink>,
        index,
        !topMenu && <UilBooks />,
      )
    ))

  const items = [
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}`}>
        Dashboard
      </NavLink>,
      'dashboard',
      !topMenu && <UilCreateDashboard />,
    ),

    getItem(
      !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">Learn</NavTitle>,
      'learn',
      null,
      null,
      'learn',
    ),
    ...subjects,
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/interviews`}>
        Interviews
      </NavLink>,
      'interviews',
      !topMenu && <UilLifeRing />,
    ),
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
            `${mainPathSplit.length === 1 ? 'dashboard' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
            }`,
          ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
