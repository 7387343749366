import actions from './actions';

const {

  GET_NGROK_URL_BEGIN,
  GET_NGROK_URL_SUCCESS,
  GET_NGROK_URL_ERR

} = actions;

const ngrokInitialState = {
  data: null,
  loading: false,
  error: null,
};

const getNgrokUrlReducer = (state = ngrokInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_NGROK_URL_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_NGROK_URL_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GET_NGROK_URL_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};



export { getNgrokUrlReducer };
