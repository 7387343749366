import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Cards } from '../../components/cards/frame/cards-frame'
import { NoteCardWrap } from '../note/style'
import CustomSpinner from '../ui-elements/spinner/CustomSpinner'
import { Col, Modal, Result, Row, Table, Button, PageHeader, message, Collapse, Tooltip } from 'antd'
import { Main, TableWrapper } from '../styled'
import { Button as ButtonComponent } from '../../components/buttons/buttons'
import { GetStudentInterviews, getInterviewAttemptsById } from '../../redux/interview/actionCreator'
import actions from '../../redux/interview/actions'
import { useDispatch, useSelector } from 'react-redux'
import { ProjectList } from '../project/style'
import { ActionWrapper, StepsStyle } from '../../components/steps/style'
import UilArrowRight from '@iconscout/react-unicons/icons/uil-arrow-right';
import UilArrowLeft from '@iconscout/react-unicons/icons/uil-arrow-left';
import UilEye from '@iconscout/react-unicons/icons/uil-eye';
import UilImport from '@iconscout/react-unicons/icons/uil-import';
import UilRefresh from '@iconscout/react-unicons/icons/uil-refresh';
import WaveSurfer from 'wavesurfer.js'
import FontAwesome from 'react-fontawesome';
import jsPDF from 'jspdf'
import 'jspdf-autotable';
import { AutoComplete } from '../../components/autoComplete/autoComplete'
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { Page, Text, Document, StyleSheet, PDFDownloadLink, View } from '@react-pdf/renderer';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';
import UilMinus from '@iconscout/react-unicons/icons/uil-minus';
import UilExport from "@iconscout/react-unicons/icons/uil-export";

const { getInterviewAttemptsSuccess } = actions

export default function Interviews() {
    const dispatch = useDispatch()

    const { studentInterviews, isStudentInterviewsLoading, interviewAttempts, isInterviewAttemptsLoading } = useSelector((state) => {
        return {
            studentInterviews: state.studentInterviews.data,
            isStudentInterviewsLoading: state.studentInterviews.loading,
            interviewAttempts: state.interviewAttempts.data,
            isInterviewAttemptsLoading: state.interviewAttempts.loading,
        };
    });


    useEffect(() => {
        loadInterviews()
    }, [])

    const loadInterviews = () => {
        dispatch(GetStudentInterviews())
    }

    useEffect(() => {
        if (dispatch) {
            dispatch(getInterviewAttemptsSuccess([]))
            setFilteredData([])
            setTableData([])
            setModalVisisble(false)
            setQuestionIndex(0)
            setRowData(null)
        }
    }, []);

    const [filteredData, setFilteredData] = useState([])
    const [tableData, setTableData] = useState([])
    const [modalVisisble, setModalVisisble] = useState(false)
    const [rowData, setRowData] = useState(null)
    const [questionIndex, setQuestionIndex] = useState(0)
    const waveSurferRef = useRef(null);

    useEffect(() => {
        if (studentInterviews) {
            const data = studentInterviews.sort((a, b) => a.starttime < b.starttime ? 1 : -1).map((obj, index) => ({
                sno: index + 1,
                key: index + 1,
                id: obj.id,
                scheduleName: obj.scheduleName,
                interviewStatus: obj.interviewstatus,
                questionCount: obj.questionids.length,
                questionsattempted: obj.questionsattempted,
                interviewstatus: obj.interviewstatus,
                attemptedon: obj.starttime && new Date(obj.starttime).toLocaleString(),
                avgscore: obj.avgscore === -1 ? 0 : Math.round(obj.avgscore),
                answered_questions: `${obj.questionsattempted}/${obj.questionids.length}`
            }))
            setFilteredData(data)
            setTableData(data)
        }
        else {
            setFilteredData([])
            setTableData([])
        }
    }, [studentInterviews])

    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
        },
        {
            title: 'Interview Id',
            dataIndex: 'scheduleName',
            key: 'scheduleName',
        },
        // {
        //     title: 'Questions',
        //     dataIndex: 'questionCount',
        //     key: 'questionCount',
        //     sorter: (a, b) => a.questionCount > b.questionCount ? 1 : -1,
        // },
        // {
        //     title: 'Answered',
        //     dataIndex: 'questionsattempted',
        //     key: 'questionsattempted',
        //     sorter: (a, b) => a.questionsattempted > b.questionsattempted ? 1 : -1,
        // },
        {
            title: 'Answered',
            dataIndex: 'answered_questions',
            key: 'answered_questions',
        },
        {
            title: 'Grade (max 10)',
            dataIndex: 'avgscore',
            key: 'avgscore',
            sorter: (a, b) => a.avgscore > b.avgscore ? 1 : -1,
        },
        {
            title: 'Taken On',
            dataIndex: 'attemptedon',
            key: 'attemptedon',
        },
        {
            title: 'Status',
            dataIndex: 'interviewStatus',
            key: 'interviewStatus',
            filters: studentInterviews && studentInterviews.Error === false && studentInterviews.length !== 0 ? Array.from(new Set(studentInterviews.map(interview => interview.interviewstatus == null ? "ABSENT" : interview.interviewstatus))).map(interviewstatus => ({ key: interviewstatus, text: interviewstatus, value: interviewstatus })) : [],
            onFilter: (value, record) => record.interviewstatus === value,
        },
        {
            title: 'Report',
            render: (_, row) => (
                <div>
                    {
                        row.interviewStatus === "EVALUATING" ?
                            <div className='text-danger'>Evaluating</div>
                            :
                            <ButtonComponent size='small' type='link' outlined disabled={row.interviewStatus === "STARTED" || row.interviewStatus === "INCOMPLETE"} onClick={() => { setModalVisisble(true); dispatch(getInterviewAttemptsById(row.id)); setRowData(row); setQuestionIndex(0) }} ><UilEye size={18} /></ButtonComponent>
                    }
                </div>
            )
        },
    ];

    const handlePrevQuestion = () => {
        if (questionIndex + 1 !== 0) {
            setQuestionIndex(questionIndex - 1);
        }
    }

    const handleNextQuestion = () => {
        if (questionIndex + 1 < interviewAttempts.length) {
            setQuestionIndex(questionIndex + 1);
        }
    }

    const AudioPlayer = ({ audioUrl }) => {
        const [isAudioLoaded, setIsAudioLoaded] = useState(false);
        const [isErrorFound, setIsErrorFound] = useState(false)
        useEffect(() => {
            if (audioUrl !== "--" && audioUrl !== null) {
                // Initialize WaveSurfer when the component mounts
                waveSurferRef.current = WaveSurfer.create({
                    container: '#waveform', // Use a div with the id 'waveform' as the container
                    waveColor: '#0aa3a3',
                    progressColor: '#006666',
                    barWidth: 2,
                    barGap: 1,
                    barRadius: 2,
                    height: 20
                });
                waveSurferRef.current.on('ready', () => {
                    setIsAudioLoaded(true)
                })
                waveSurferRef.current.on('error', () => {
                    setIsErrorFound(true)
                })

                // Add event listener for the finish event
                waveSurferRef.current.on('finish', () => {
                    setIsPlaying(false);
                });

                return () => {
                    // Clean up when the component unmounts
                    waveSurferRef.current.destroy();
                    setIsPlaying(false)
                };
            }
        }, []);

        useEffect(() => {
            // Load the new audio URL when it changes
            if (audioUrl && audioUrl !== "--") {
                waveSurferRef.current.load(process.env.REACT_APP_API_ENDPOINT + audioUrl);
            }
        }, [audioUrl]);

        const [isPlaying, setIsPlaying] = useState(false)
        const handlePlayPause = () => {
            if (waveSurferRef.current) {
                if (waveSurferRef.current.isPlaying()) {
                    waveSurferRef.current.pause();
                    setIsPlaying(false);
                } else {
                    waveSurferRef.current.play();
                    setIsPlaying(true);
                }
            } else {
                console.error('WaveSurfer instance is not initialized.');
                // Handle the error or display a message to the user
            }
        };
        return <div className='text-center'>
            {
                !isAudioLoaded && interviewAttempts[questionIndex].question?.questionType == "VIVA" &&
                <p style={{ fontSize: 16 }}>Loading audio...</p>
            }
            <div id="waveform" style={{ display: `${isErrorFound ? 'none' : 'inline'}` }} />
            {
                !isErrorFound && isAudioLoaded ?
                    <>
                        {
                            (audioUrl !== "--" && audioUrl !== null) &&
                            <Button onClick={handlePlayPause} className='mb-10 icon-btn d-inline' type='link'>
                                {isPlaying ? <FontAwesome name="pause" style={{ fontSize: '22px', background: '#eee', padding: '6px 18px', borderRadius: '6px' }} /> : <FontAwesome name="play" style={{ fontSize: '22px', background: '#eee', padding: '6px 18px', borderRadius: '6px' }} />}
                            </Button>
                        }
                    </>
                    :
                    isErrorFound && <p className='text-danger'>Failed to load audio</p>
            }
        </div>;
    };

    // const generatePDF = () => {
    //     const now = new Date();
    //     const doc = new jsPDF('landscape');
    //     doc.setFont('Arial');
    //     doc.setFontSize(12);
    //     let startY = 22;
    //     let isFirstPage = true;
    //     let totalPagesExp = '{total_pages_count_string}';

    //     const data = interviewAttempts.map((obj, index) => [
    //         index + 1,
    //         obj.question.question?.replace(/<[^>]*>|&nbsp;/g, ' '),
    //         obj.answer?.replace(/<[^>]*>|&nbsp;/g, ' ') || 'NA',
    //         obj.evaluationfeedback1 === null ? 'NA' : (obj.evaluationfeedback1 + "\n\nScore: " + (obj.evaluationscore1 === -1 ? 'NA' : obj.evaluationscore1)),
    //         // obj.evaluationscore1,
    //         obj.evaluationfeedback2 === null ? 'NA' : (obj.evaluationfeedback2 + "\n\nScore: " + (obj.evaluationscore2 === -1 ? 'NA' : obj.evaluationscore2)),
    //         // obj.evaluationscore2,
    //         obj.avgscore === -1 ? 'NA' : obj.avgscore
    //     ]);

    //     doc.autoTable({
    //         head: [['#', 'Question', "Student Answer", 'Evaluation Feedback 1', 'Evaluation Feedback 2', 'Avg Score']],
    //         body: data,
    //         startY: startY,
    //         theme: 'grid',
    //         columnStyles: {
    //             0: { cellWidth: 8 },
    //             1: { cellWidth: 50 },
    //             2: { cellWidth: 80 },
    //             3: { cellWidth: 53 },
    //             // 4: { cellWidth: 22 },
    //             4: { cellWidth: 53 },
    //             // 6: { cellWidth: 22 },
    //             5: { cellWidth: 25 },
    //         },

    //         didDrawPage: function (data) {
    //             const pageWidth = doc.internal.pageSize.width;

    //             if (isFirstPage) {
    //                 doc.setFontSize(12);
    //                 doc.text(`Schedule: ${rowData.scheduleName}`, startY, 10);
    //                 doc.text(`Avg Score: ${rowData?.avgscore}`, pageWidth - startY, 10, { align: 'right' });
    //                 // doc.text(`Difficulty Level: ${rowData?.difficultylevel}`, startY, 22);
    //                 // doc.text(`Avg Score: ${rowData?.avgscore}`, pageWidth - startY, 22, { align: 'right' });
    //                 doc.text(``, pageWidth - startY, 22);
    //                 isFirstPage = false;
    //             }

    //             if (isFirstPage) {
    //                 startY = 22
    //                 data.settings.margin.top = startY;
    //             } else {
    //                 startY = 10;
    //                 data.settings.margin.top = startY;
    //             }
    //             var str = 'Page ' + doc.internal.getNumberOfPages();
    //             if (typeof doc.putTotalPages === 'function') {
    //                 str = str + ' of ' + totalPagesExp;
    //             }
    //             doc.setFontSize(10);
    //             const pageHeight = doc.internal.pageSize.height ? doc.internal.pageSize.height : doc.internal.pageSize.getHeight();
    //             const centerPos = pageWidth / 2;
    //             const textWidth = (doc.getStringUnitWidth('Interview taken on: ' + rowData.attemptedon) * doc.internal.getFontSize() / doc.internal.scaleFactor) - 50;
    //             const xPos = centerPos - textWidth / 2;
    //             doc.text(`Prashn Manch`, startY, pageHeight - 10);
    //             doc.text('Interview taken on: ' + rowData.attemptedon, xPos, pageHeight - 10, { align: 'center' });
    //             doc.text(str, pageWidth - startY + 20, pageHeight - 10, { align: 'right' });

    //         },
    //     });
    //     if (typeof doc.putTotalPages === 'function') {
    //         doc.putTotalPages(totalPagesExp)
    //     }

    //     doc.save(`${rowData?.scheduleName}_evaluation_report.pdf`);
    // };

    const searchkeys = ['scheduleName']
    const handleSearch = (e) => {
        const value = e;
        const searchData = tableData.length !== 0 && tableData.filter((obj) => searchkeys.some((key) => obj[key].toLowerCase().includes(value.toLowerCase())))
        setFilteredData(searchData)
    }


    // Define styles
    const styles = StyleSheet.create({
        page: {
            padding: 40,
            fontFamily: 'Helvetica',
            lineHeight: 1.5,
            fontSize: 12,
        },
        reportTitle: {
            textAlign: 'center',
            color: '#0056b3',
            fontSize: 16,
            marginBottom: 10,
            textTransform: 'uppercase'
        },
        header: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 20,
            paddingBottom: 10,
            borderBottom: '1px solid #ddd',
            color: '#333',
        },
        section: {
            marginBottom: 10,
            padding: 10,
            backgroundColor: '#fafafa',
            borderRadius: 5,
            border: '1px solid #ddd',
            breakInside: 'avoid', // Prevent section from breaking within
        },
        sectionHeader: {
            fontWeight: 'bold',
            marginBottom: 5,
            color: '#0056b3',
        },
        questionHeader: {
            fontWeight: 'bold',
            marginBottom: 5,
        },
        answer: {
            fontFamily: 'Courier',
            fontSize: 12,
            marginTop: 15
        },
        footer: {
            position: 'absolute',
            bottom: 20,
            left: 40,
            right: 40,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: 10,
            color: '#777',
        },
        fontsize11: {
            fontSize: 11,
        },
        fontsize12: {
            fontSize: 12,
        },
        justifyspacebetween: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginVertical: 5,
        },
        boldText: {
            fontWeight: 'bold',
        },
    });


    // PDF Document component
    const MyDocument = () => (
        <Document>
            {interviewAttempts?.map((item, index) => (
                <Page key={index} size={'A4'} style={styles.page}>
                    {index === 0 && (
                        <View style={styles.reportTitle}>
                            <Text>Prashna Manch Evaluation Report</Text>
                        </View>
                    )}
                    {/* {index === 0 && (
                        <View style={styles.header}>
                            <View style={styles.headerLeft}>
                                <Text>Roll No.: {rowData?.rollno}</Text>
                                <Text>Name: {rowData?.studentName}</Text>
                            </View>
                            <View style={styles.headerRight}>
                                <Text>Schedule: {rowData?.scheduledName}</Text>
                                <Text>Grade: {rowData?.avgscore}</Text>
                            </View>
                        </View>
                    )} */}
                    <View style={[styles.section]} wrap={true}>
                        <View style={styles.justifyspacebetween}>
                            <View>
                                <Text style={[styles.sectionHeader, styles.fontsize11]}>
                                    Question Type: {item.question.questionType}
                                </Text>
                            </View>
                            <View>
                                <Text style={[styles.fontsize11]}>
                                    Avg Grade: {Math.round(item.avgscore)}
                                </Text>
                            </View>
                            <View>
                                <Text style={[styles.fontsize11]}>
                                    Status: {item.status}
                                </Text>
                            </View>
                        </View>

                        <Text style={styles.questionHeader}>Q{index + 1}. {item.question.question?.replace(/[`'"]/g, '')}</Text>
                        <Fragment>
                            <Text>
                                {
                                    (item.question.questionType === "JUMBLED CODE" || item.question.questionType === "EXPLAIN CODE" || item.question.questionType === "DEBUG CODE" || item.question.questionType === "PREDICT OUTPUT") &&
                                    item.question.modifiedProgram?.replace(/[`"]/g, '\'') // Replacing double quotes with single quotes
                                }
                            </Text>
                        </Fragment>
                        <View style={styles.answer}>
                            <Text style={styles.sectionHeader}>Answer</Text>
                            <View>
                                {/* Removing double quotes and single quotes from viva and theory */}
                                <Fragment>
                                    <Text>
                                        {
                                            (item.question.questionType != "VIVA" || item.question.questionType != "THEORY") ?
                                                (item.audiolink != null && (item.answer == "" || item.answer == null)) ?
                                                    <Text>
                                                        It seems that the audio quality of your recording was affected by background noise or low volume. To improve transcription accuracy, please ensure your recordings are clear and free from distracting sounds. Consider speaking directly into the microphone to enhance clarity. Thank you for your attention to this detail!
                                                    </Text>
                                                    :
                                                    item.answer
                                                :
                                                item.answer?.replace(/[`'"]/g, '')
                                        }
                                    </Text>
                                </Fragment>
                                {/* <Fragment>
                                    {
                                        item.answer
                                            ? item.answer.split(/(?<!\w\.\w.)(?<![A-Z][a-z]\.)(?<=\.|\?)\s+/g).map((sentence, index) => (
                                                <Text key={index}>{sentence.trim() + (sentence.endsWith('.') ? '' : '.')}</Text>
                                            ))
                                            : <Text>No answer provided.</Text>
                                    }
                                </Fragment> */}
                            </View>
                        </View>
                        <View style={{ marginTop: 10 }} wrap={true}>
                            <Text style={styles.sectionHeader}>Evaluations</Text>
                            <View style={{ borderBottom: '1px solid #ddd', paddingBottom: 10 }}>
                                <View style={styles.justifyspacebetween}>
                                    <Text style={styles.fontsize12}>Feedback-1</Text>
                                    <Text style={styles.fontsize12}>Score-1: {item.evaluationscore1}</Text>
                                </View>
                                <View>
                                    <Fragment>
                                        <Text>
                                            {item.evaluationfeedback1?.replace(/[!`'"]/g, '')?.replace(/\u2013|\u2014/g, '-')}
                                        </Text>
                                    </Fragment>
                                </View>
                            </View>
                            <View style={{ borderBottom: '1px solid #ddd', paddingBottom: 10 }}>
                                <View style={styles.justifyspacebetween}>
                                    <Text style={styles.fontsize12}>Feedback-2</Text>
                                    <Text style={styles.fontsize12}>Score-2: {item.evaluationscore2}</Text>
                                </View>
                                <View>
                                    <Fragment>
                                        <Text>
                                            {item.evaluationfeedback2?.replace(/[!`'"]/g, '')?.replace(/\u2013|\u2014/g, '-')}
                                        </Text>
                                    </Fragment>
                                </View>
                            </View>
                            <View>
                                <View style={styles.justifyspacebetween}>
                                    <Text style={styles.fontsize12}>Feedback-3</Text>
                                    <Text style={styles.fontsize12}>Score-3: {item.evaluationscore3}</Text>
                                </View>
                                <View>
                                    <Fragment>
                                        <Text>
                                            {item.evaluationfeedback3?.replace(/[!`'"]/g, '')?.replace(/[^\x00-\x7F]+/g, '')}
                                        </Text>
                                    </Fragment>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={styles.footer}>
                        <Text>Prashna Manch</Text>
                        <Text>Interview Taken on: {rowData.attemptedon}</Text>
                        <Text>Page {index + 1}</Text>
                    </View>
                </Page>
            ))}
        </Document>
    );

    return (
        <Main>
            <PageHeader title="Interviews" />
            <Cards headless>
                <NoteCardWrap>
                    {
                        isStudentInterviewsLoading ?
                            <div className="custom-spin">
                                <CustomSpinner Message={'Loading'} />
                            </div>
                            :
                            <div>
                                <div className="d-flex align-center-v justify-content-end mb-20">
                                    <ButtonComponent type='primary' outlined className='mr-10' onClick={() => loadInterviews()}><UilRefresh /></ButtonComponent>
                                    {
                                        tableData.length !== 0 &&
                                        <AutoComplete id="" onSearch={handleSearch} dataSource={filteredData} placeholder="Search here" patterns />
                                    }
                                </div>
                                {
                                    tableData.length === 0 ?
                                        <div>
                                            <Result
                                                title="OOPS! It seems that there are no interviews found."
                                                status="warning"
                                            />
                                        </div>
                                        :
                                        <TableWrapper>
                                            <ProjectList>
                                                <div className="table-responsive">
                                                    <Table size='large' dataSource={filteredData} columns={columns} />
                                                </div>
                                            </ProjectList>
                                        </TableWrapper>
                                }
                            </div>

                    }

                    <Modal
                        open={modalVisisble}
                        title={<span>EVALUATION REPORT: <i style={{ fontWeight: 400 }}>{rowData?.scheduleName}</i>, <i style={{ fontWeight: 400 }}>{rowData?.attemptedon}</i>, <i>Grade</i> - {Math.round(rowData?.avgscore)}</span>}
                        width={1400}
                        centered
                        maskClosable={false}
                        footer={false}
                        onCancel={() => { setModalVisisble(false) }}
                    >
                        {
                            isInterviewAttemptsLoading ?
                                <div className="spin">
                                    <CustomSpinner Message={'Loading... Please wait.'} />
                                </div>
                                :
                                interviewAttempts.length === 0 ?
                                    <div>
                                        <Result
                                            title="Evaluations not found!"
                                            status="warning"
                                        />
                                    </div>
                                    :
                                    interviewAttempts.length !== 0 &&
                                    <div>
                                        <div>
                                            <div>
                                                {/* <StepsStyle current={questionIndex} size="small">
                                                {interviewAttempts.map((question, index) => (
                                                    <StepsStyle.Step key={index} />
                                                ))}
                                            </StepsStyle> */}
                                                <div className='mt-20 d-flex justify-content-between align-center-v'>
                                                    <div>
                                                        <span className={`status mr-2 ${interviewAttempts[questionIndex].status == "SKIPPED" ? 'text-danger' : ''}`}>{interviewAttempts[questionIndex].status}</span>
                                                        <span className='status'>{interviewAttempts[questionIndex].question?.questionType}</span>
                                                    </div>
                                                    <div className='d-flex align-center-v'>
                                                        {/* <span className='mr-5'>Avg score: {interviewAttempts[0].interview.avgscore}</span> */}
                                                        {/* <ButtonComponent type={'primary'} outlined onClick={() => { DownloadPDF() }} className='d-flex align-center-v'>
                                                        <UilImport size={16} /> &nbsp;
                                                        <span>PDF</span>
                                                    </ButtonComponent> */}
                                                        {/* <PDFDownloadLink document={<MyDocument />} fileName={rowData?.scheduleName + '-evaluation-report.pdf'}>
                                                            {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download PDF')}
                                                        </PDFDownloadLink> */}
                                                    </div>
                                                </div>
                                                {
                                                    interviewAttempts[questionIndex].question?.questionType == "CODE" || interviewAttempts[questionIndex].question?.questionType === "PREDICT OUTPUT" || interviewAttempts[questionIndex].question?.questionType === "EXPLAIN CODE" || interviewAttempts[questionIndex].question?.questionType === "DEBUG CODE" ?
                                                        <div className="interviews-page-panel mt-10" >
                                                            <PanelGroup direction="vertical">
                                                                <Panel key='top' minSize={10}>
                                                                    <PanelGroup direction="horizontal">
                                                                        <Panel className='scrollbar-css' key="left" collapsible={false} collapsedSize={10} defaultSize={50} minSize={10} style={{ overflow: 'auto' }}>
                                                                            {
                                                                                interviewAttempts[questionIndex].question?.questionType == "CODE" && rowData?.attemptedOn > new Date() ?
                                                                                    interviewAttempts[questionIndex].question?.question.split('\n').map((line, index) => {
                                                                                        if (line.trim() !== '') {
                                                                                            return (
                                                                                                <li
                                                                                                    key={index}
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                    }}
                                                                                                >
                                                                                                    <span>{line}</span>
                                                                                                </li>
                                                                                            );
                                                                                        } else {
                                                                                            return null;
                                                                                        }
                                                                                    })
                                                                                    :
                                                                                    <h3 className='p-2 d-flex' dangerouslySetInnerHTML={{ __html: (questionIndex + 1) + '. ' + interviewAttempts[questionIndex].question?.question.toString('html') }}></h3>
                                                                            }
                                                                            {
                                                                                (interviewAttempts[questionIndex].question?.questionType === "PREDICT OUTPUT" || interviewAttempts[questionIndex].question?.questionType === "EXPLAIN CODE" || interviewAttempts[questionIndex].question?.questionType === "DEBUG CODE") &&
                                                                                    interviewAttempts[questionIndex].question?.modifiedProgram ? interviewAttempts[questionIndex].question.modifiedProgram.split('\n').map((line, index) => {
                                                                                        if (line.trim() !== '') {
                                                                                            return (
                                                                                                <li
                                                                                                    key={index}
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                    }}
                                                                                                >
                                                                                                    <span>{line}</span>
                                                                                                </li>
                                                                                            );
                                                                                        } else {
                                                                                            return null;
                                                                                        }
                                                                                    })
                                                                                    :
                                                                                    <div className='d-flex'>
                                                                                        NA
                                                                                    </div>
                                                                            }
                                                                        </Panel>
                                                                        <PanelResizeHandle style={{ width: '1px', backgroundColor: '#ddd' }} />
                                                                        <Panel key="right" className='scrollbar-css' collapsible={false} collapsedSize={10} minSize={10} style={{ overflow: 'auto' }}>
                                                                            {
                                                                                interviewAttempts[questionIndex].question?.questionType === "CODE" || interviewAttempts[questionIndex].question?.questionType === "EXPLAIN CODE" ?
                                                                                    <>
                                                                                        {
                                                                                            interviewAttempts[questionIndex].question?.questionType == "CODE" && rowData?.attemptedOn > new Date() ?
                                                                                                interviewAttempts[questionIndex].answer.split('\n').map((line, index) => {
                                                                                                    if (line.trim() !== '') {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={index}
                                                                                                                style={{
                                                                                                                    display: 'flex',
                                                                                                                }}
                                                                                                            >
                                                                                                                <span>{line}</span>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } else {
                                                                                                        return null;
                                                                                                    }
                                                                                                })
                                                                                                :
                                                                                                <p className='p-2' dangerouslySetInnerHTML={{ __html: 'Answer: ' + interviewAttempts[questionIndex].answer }}></p>
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        Answer: {
                                                                                            (interviewAttempts[questionIndex].question?.questionType === "PREDICT OUTPUT" || interviewAttempts[questionIndex].question?.questionType === "DEBUG CODE") &&
                                                                                                interviewAttempts[questionIndex].answer ? interviewAttempts[questionIndex].answer.split('\n').map((line, index) => {
                                                                                                    if (line.trim() !== '') {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={index}
                                                                                                                style={{
                                                                                                                    display: 'flex',
                                                                                                                }}
                                                                                                            >
                                                                                                                <span>{line}</span>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } else {
                                                                                                        return null;
                                                                                                    }
                                                                                                })
                                                                                                :
                                                                                                <div className='d-flex'>
                                                                                                    NA
                                                                                                </div>
                                                                                        }
                                                                                    </>
                                                                            }
                                                                        </Panel>
                                                                    </PanelGroup>
                                                                </Panel>
                                                                <PanelResizeHandle style={{ height: '1px', backgroundColor: '#ddd' }} />
                                                                <Panel className='scrollbar-css' key="bottom" collapsible={false} collapsedSize={10} minSize={10} style={{ overflow: 'auto' }}>
                                                                    <Evaluations interviewAttempts={interviewAttempts} questionIndex={questionIndex} />
                                                                </Panel>
                                                            </PanelGroup>
                                                        </div>
                                                        :
                                                        <div className={'mt-10'} style={{ textAlign: 'left' }}>
                                                            <div style={{ marginTop: '15px', marginBottom: '10px' }} className='d-flex justify-content-between align-center-v'>
                                                                <h3 className='d-flex' dangerouslySetInnerHTML={{ __html: (questionIndex + 1) + '. ' + interviewAttempts[questionIndex].question?.question.toString('html') }}></h3>
                                                                {/* <div>
                                                                <span className='status'>{interviewAttempts[questionIndex].status}</span>
                                                                <span className='status'>{interviewAttempts[questionIndex].question?.questionType}</span>
                                                            </div> */}
                                                            </div>
                                                            <div>
                                                                {
                                                                    interviewAttempts[questionIndex].question?.questionType === "JUMBLED CODE" ?
                                                                        <div>
                                                                            Answer:
                                                                            {
                                                                                interviewAttempts[questionIndex].answer ? interviewAttempts[questionIndex].answer.split('\n').map((line, index) => {
                                                                                    if (line.trim() !== '') {
                                                                                        return (
                                                                                            <li
                                                                                                key={index}
                                                                                                style={{
                                                                                                    display: 'flex',
                                                                                                }}
                                                                                            >
                                                                                                <span>{line}</span>
                                                                                            </li>
                                                                                        );
                                                                                    } else {
                                                                                        return null;
                                                                                    }
                                                                                })
                                                                                    :
                                                                                    <div className='d-flex'>
                                                                                        NA
                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                        :
                                                                        interviewAttempts[questionIndex].audiolink !== null && (interviewAttempts[questionIndex].answer == "" || interviewAttempts[questionIndex].answer == null) ?
                                                                            <p className='text-danger'>It seems that the audio quality of your recording was affected by background noise or low volume. To improve transcription accuracy, please ensure your recordings are clear and free from distracting sounds. Consider speaking directly into the microphone to enhance clarity. Thank you for your attention to this detail!</p>
                                                                            :
                                                                            <p dangerouslySetInnerHTML={{
                                                                                __html: 'Answer: ' + interviewAttempts[questionIndex].answer
                                                                            }}></p>
                                                                }

                                                                {
                                                                    interviewAttempts[questionIndex].status === "ATTEMPTED" &&
                                                                    <AudioPlayer audioUrl={interviewAttempts[questionIndex].audiolink} />
                                                                }
                                                            </div>
                                                            <Evaluations interviewAttempts={interviewAttempts} questionIndex={questionIndex} />
                                                        </div>
                                                }
                                            </div>
                                            <ActionWrapper>
                                                <div className="step-action-wrap pb-20">
                                                    <div className="step-action-inner">
                                                        <div className="steps-action">

                                                            <Button className="btn-prev" type="white" outlined="true" style={{ color: '#555', marginRight: '10px' }} onClick={handlePrevQuestion} disabled={questionIndex === 0}>
                                                                <UilArrowLeft />
                                                                Previous
                                                            </Button>
                                                            {questionIndex < (interviewAttempts.length !== 0 && interviewAttempts.length - 1) && (
                                                                <Button
                                                                    className="btn-next"
                                                                    type="primary"
                                                                    onClick={handleNextQuestion}
                                                                >
                                                                    Next
                                                                    <UilArrowRight />
                                                                </Button>
                                                            )}

                                                        </div>
                                                    </div>
                                                </div>
                                            </ActionWrapper>
                                        </div>
                                    </div>
                        }
                    </Modal>
                </NoteCardWrap>
            </Cards>
        </Main>
    )
}


const Evaluations = ({ interviewAttempts, questionIndex }) => {
    return (
        <div>
            {
                (interviewAttempts[questionIndex].evaluationfeedback1 == null && interviewAttempts[questionIndex].evaluationfeedback2 == null && interviewAttempts[questionIndex].evaluationfeedback3 == null) ?
                    <div style={{ background: '#fafafa', borderRadius: '6px', padding: '10px' }}>
                        <h4 className='text-center text-danger'>Evaluations not found</h4>
                    </div>
                    :
                    <div style={{ background: '#fafafa', borderRadius: '6px', padding: '10px' }}>
                        <h4 className='mt-10 d-flex justify-content-end'>Question Best score {Math.round(interviewAttempts[questionIndex].avgscore)}</h4>
                        <CollapseComponent QObj={interviewAttempts[questionIndex]} index={1} />
                        <CollapseComponent QObj={interviewAttempts[questionIndex]} index={2} />
                        <CollapseComponent QObj={interviewAttempts[questionIndex]} index={3} />
                    </div>
            }
        </div>
    )
}

const CollapseComponent = ({ QObj, index }) => {
    const { Panel } = Collapse;
    let key = `evaluationfeedback${(index.toString())}`
    let scoreKey = `evaluationscore${(index.toString())}`
    return (
        <div>
            <Collapse defaultActiveKey={['1']} bordered={false} expandIcon={({ isActive }) => (isActive ? <UilMinus /> : <UilPlus />)} className='m-0 mb-2' >
                <Panel header={<div className='d-flex justify-content-between'>
                    <p className='m-0'>Evaluation Feedback {index}</p><p className='fontsize-13'>{QObj[key] !== null && "Score: " + QObj[scoreKey]}</p></div>}>
                    <p style={{ textAlign: 'justify', paddingBottom: '15px' }} className='m-0'>
                        {
                            QObj[key] === null ?
                                'NA'
                                :
                                QObj[key]
                        }
                    </p>
                </Panel>
            </Collapse>
        </div>
    );
}