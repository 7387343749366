import {
    GET_ANSWERS_BEGIN,
    GET_ANSWERS_SUCCESS, 
    GET_ANSWERS_ERROR, 
    MANUAL_EVALUATION_BEGIN, 
    MANUAL_EVALUATION_SUCCESS, 
    MANUAL_EVALUATION_ERROR,
    READ_QA_REPORTS_BEGIN,
    READ_QA_REPORTS_SUCCESS,
    READ_QA_REPORTS_ERROR
} from './actions';

const initialState = {
    data: [],
    loading: false,
    error: null,
};

const EvaluationResInitialState = {
    data: null,
    loading: false,
    error: null,
};

const QaReportsInitialState = {
    data: [],
    loading: false,
    error: null,
};

const AnswersReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ANSWERS_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case GET_ANSWERS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: false,
                loading: false,
            };
        case GET_ANSWERS_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

const ManualEvaluationReducer = (state = EvaluationResInitialState, action) => {
    switch (action.type) {
        case MANUAL_EVALUATION_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case MANUAL_EVALUATION_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: false,
                loading: false,
            };
        case MANUAL_EVALUATION_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

const QaReportsReducer = (state = QaReportsInitialState, action) => {
    switch (action.type) {
        case READ_QA_REPORTS_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case READ_QA_REPORTS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: false,
                loading: false,
            };
        case READ_QA_REPORTS_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }

};

export { AnswersReducer, ManualEvaluationReducer, QaReportsReducer };
