import React, { useEffect, useRef, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import {
  UilBooks,
  UilBook,
} from '@iconscout/react-unicons';
import { TopMenuStyle } from './Style';
import { useDispatch, useSelector } from 'react-redux';
import { subjectsUnitsDataRead } from '../redux/teacherSubjectsUnits/actionCreator';
import { getItem, removeItem } from '../utility/localStorageControl';
import { Button } from '../components/buttons/buttons';
import CreateTopic from '../container/teacher/CreateTopic';
import { Tooltip } from 'antd';

function TeacherMenu() {
  const path = '/teacher';

  const dispatch = useDispatch();

  const [menuVisible, setMenuViible] = useState(false)
  const [studentMenuVisible, setStudentsMenuViible] = useState(false)
  const [prepareContentMenuVisible, setPrepareContentMenuViible] = useState(false)
  const dropdownRef = useRef(null);
  const studentsDropdownRef = useRef(null);
  const prepareContentRef = useRef(null);

  const addParentActive = (event) => {
    removeItem('activetopic')
    document.querySelectorAll('.parent').forEach((element) => {
      element.classList.remove('active');
    });

    const hasSubMenuLeft = event.currentTarget.closest('.has-subMenu-left');
    const megaMenu = event.currentTarget.closest('.megaMenu-wrapper');
    if (!megaMenu) {
      event.currentTarget.closest('ul').previousSibling.classList.add('active');
      if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active');
    } else {
      event.currentTarget.closest('.megaMenu-wrapper').previousSibling.classList.add('active');
    }
  };


  useEffect(() => {
    if (dispatch) {
      dispatch(subjectsUnitsDataRead());
    }
  }, [dispatch]);

  const { SubjectsUnits } = useSelector((state) => {
    return {
      SubjectsUnits: state.SubjectsUnits.data,
      isLoading: state.SubjectsUnits.loading,
    };
  });

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setMenuViible(false);
      }
      if (studentsDropdownRef.current && !studentsDropdownRef.current.contains(event.target)) {
        setStudentsMenuViible(false)
      }
      if (prepareContentRef.current && !prepareContentRef.current.contains(event.target)) {
        setPrepareContentMenuViible(false)
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const toggleMegamenu = () => {
    setMenuViible(!menuVisible)
    setStudentsMenuViible(false)
    setPrepareContentMenuViible(false)
  }

  const toggleStudentsmenu = () => {
    setStudentsMenuViible(!studentMenuVisible)
    setPrepareContentMenuViible(false)
    setMenuViible(false)
  }

  const togglePrepareContentmenu = () => {
    setMenuViible(false)
    setStudentsMenuViible(false)
    setPrepareContentMenuViible(!prepareContentMenuVisible)
  }

  const [createTopicModal, setCreateTopicModal] = useState(false)

  const currentFS = getItem('activeFSid')

  return (
    <TopMenuStyle>
      <CreateTopic open={createTopicModal} close={setCreateTopicModal} />
      <div className="ninjadash-top-menu">
        <ul>
          <li className="">
            <NavLink to={`${path}`} >
              Dashboard
            </NavLink>
          </li>

          <li className="has-subMenu" onClick={toggleMegamenu} ref={dropdownRef}>
            <Link to="#" className="">
              Subjects
            </Link>
            {
              SubjectsUnits !== null && SubjectsUnits.subjects.length !== 0 ?
                menuVisible &&
                <ul className="subMenu">
                  {
                    SubjectsUnits.subjects.sort((a, b) => a.name > b.name ? 1 : -1).filter((obj) => obj?.fs?.id === parseInt(currentFS)).map((subjectObj, subjectIndex) =>
                      <li className="has-subMenu-left non-clickable" key={subjectIndex}>
                        {
                          subjectObj?.fs?.id !== parseInt(currentFS) ?
                            <Link to="#">
                              <UilBooks />
                              <div style={{ whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis', width: '150px', cursor: 'not-allowed' }} aria-disabled={true} >
                                <Tooltip placement='top' title={subjectObj.name}>
                                  {subjectObj.name}
                                </Tooltip>
                              </div>
                            </Link>
                            :
                            <Link to="#" className="parent" >
                              <UilBooks />
                              <div style={{ whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis', width: '150px' }}>
                                {subjectObj.name}
                              </div>
                            </Link>
                        }
                        {
                          subjectObj.units.length !== 0 ?
                            <ul className="subMenu">
                              {
                                subjectObj.units.sort((a, b) => a.name > b.name ? 1 : -1).map((unitObj, unitIndex) =>
                                  <li key={unitIndex} onClick={toggleMegamenu}>
                                    <NavLink onClick={addParentActive} to={`${path}/subjects/subject/${subjectObj.id}/unit/${unitObj.id}/`}>
                                      <UilBook />
                                      {unitObj.name} ({unitObj.topics.length})
                                    </NavLink>
                                  </li>
                                )
                              }
                              <li className='align-center pt-10'>
                                <Button type='primary' outlined onClick={() => { setCreateTopicModal(true) }} >+ Create Topic</Button>
                              </li>
                            </ul>
                            :
                            <ul className="subMenu">
                              <li className='align-center'>
                                <Button type='primary' outlined onClick={() => { setCreateTopicModal(true) }} >+ Create Topic</Button>
                              </li>
                            </ul>
                        }
                      </li>
                    )
                  }
                  {
                    SubjectsUnits.subjects.sort((a, b) => a.name > b.name ? 1 : -1).filter((obj) => obj?.fs?.id !== parseInt(currentFS)).map((subjectObj, subjectIndex) =>
                      <li className="non-clickable" key={subjectIndex}>
                        {
                          <Tooltip placement='right' title={`${subjectObj.name} is in ${subjectObj?.fs?.name} (${subjectObj?.fs?.status})`}>
                            <Link to="#">
                              <UilBooks />
                              <div style={{ whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis', width: '150px', cursor: 'not-allowed' }} aria-disabled={true} >
                                {subjectObj.name}
                              </div>
                            </Link>
                          </Tooltip>
                        }
                      </li>
                    )
                  }
                </ul>
                :
                menuVisible &&
                <ul className="subMenu">
                  <li>
                    <Link to="#">
                      Subjects Not Found
                    </Link>
                  </li>
                </ul>
            }
          </li>

          {/* <li className="has-subMenu" onClick={toggleStudentsmenu} ref={studentsDropdownRef}>
            <Link to="#">
              Students
            </Link>
            {
              studentMenuVisible &&
              <ul className="subMenu">
                <li onClick={toggleStudentsmenu}>
                  <NavLink to={`${path}/monitor-students`} >
                    Activity Monitor
                  </NavLink>
                </li>
                <li onClick={toggleStudentsmenu}>
                  <NavLink to={`${path}/answers`} >
                    Q & A Submissions
                  </NavLink>
                </li>
                <li onClick={toggleStudentsmenu}>
                  <NavLink to={`${path}/qa-reports`} >
                    Q & A Reports
                  </NavLink>
                </li>
                <li onClick={toggleStudentsmenu}>
                  <NavLink to={`${path}/assessment`} >
                    Unlock Logins
                  </NavLink>
                </li>
                <li onClick={toggleStudentsmenu}>
                  <NavLink to={`${path}/lock-unlock-assessment-topics`} >
                    Lock/Unlock Topics
                  </NavLink>
                </li>

              </ul>
            }
          </li> */}

          <li className="has-subMenu" onClick={togglePrepareContentmenu} ref={prepareContentRef}>
            <Link to="#">
              Prepare Content
            </Link>
            {
              prepareContentMenuVisible &&
              <ul className="subMenu">
                <li onClick={togglePrepareContentmenu}>
                  <NavLink to={`${path}/transcribe`} >
                    Transcribe
                  </NavLink>
                </li>
                {/* <li onClick={togglePrepareContentmenu}>
                  <NavLink to={`${path}/drawing-tool`}>
                    Drawing Tool
                  </NavLink>
                </li> */}
              </ul>
            }
          </li>

          {/* <li className="">
            <NavLink to={`${path}`} >
              Schedule
            </NavLink>
          </li> */}
        </ul>
      </div>
    </TopMenuStyle>
  );
}

export default TeacherMenu;