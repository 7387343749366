import React, { lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Dashboard from '../../container/student/Dashboard';
import Units from '../../container/student/Units';
import withStudentLayout from '../../layout/withStudentLayout';
import TopicContent from '../../container/student/TopicContent';
import CustomSpinner from '../../container/ui-elements/spinner/CustomSpinner';
import ChangePassword from '../../container/ChangePassword';
import Profile from '../../container/student/Profile';
import Interviews from '../../container/student/Interviews';

const NotFound = lazy(() => import('../../container/pages/404'));

const Student = React.memo(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Suspense
      fallback={
        <div className="">
          <CustomSpinner />
        </div>
      }
    >
      <Routes>
        <Route index path="/" element={<Dashboard />} />
        {/* <Route path="/change-password" element={<ChangePassword />} /> */}
        <Route path="/student-profile/*" element={<Profile/>} />        
        <Route path="/subject/:subjectId/*" element={<Units />} />
        <Route path="/subject/:subjectId/unit/:unitId/topic/:topicId/*" element={<TopicContent />} />
        <Route path="/interviews" element={<Interviews />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default withStudentLayout(Student);
