import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { getItem } from '../../utility/localStorageControl';


const {
  dailylogincountBegin,
  dailylogincountSuccess,
  dailylogincountErr,
  currentweekBegin,
  currentweekSuccess,
  currentweekErr,
  TodaysBadgesBegin,
  TodaysBadgesSuccess,
  TodaysBadgesErr,

} = actions;


const DailyLoginCountDataRead = () => {
  return async (dispatch) => {
    try {
      const collegeName = getItem('collegeName')
      await dispatch(dailylogincountBegin());
      const query = await DataService.get(`/usermaster/dailylogin/${collegeName}`);
      const token = getItem('access_token');
      const data = query.data;
      if (token && data !== null && data.Error === false) {
        await dispatch(dailylogincountSuccess(data.payload));
      }
      else {
        await dispatch(dailylogincountSuccess(null));
      }
    } catch (err) {
      await dispatch(dailylogincountErr(err));
    }
  };
};


const CurrentweekYearwise = () => {
  return async (dispatch) => {
    try {
      const collegeId = getItem('collegeId')
      await dispatch(currentweekBegin());
      const query = await DataService.get(`/schedule/presentsems/${collegeId}`);
      const token = getItem('access_token');
      const data = query.data;
      if (token && data !== null && data.Error === false) {
        await dispatch(currentweekSuccess(data.payload));
      }
      else {
        await dispatch(currentweekSuccess(null));
      }
    } catch (err) {
      await dispatch(currentweekErr(err));
    }
  };
};

const TodaysReadAssesmentBadges = () => {
  return async (dispatch) => {
    try {
      const collegeName = getItem('collegeName')
      await dispatch(TodaysBadgesBegin());
      const query = await DataService.get(`/dwbds/badgecount/${collegeName}`);
      const token = getItem('access_token');
      const data = query.data;      
      if (token && data !== null && data.Error === false) {
        await dispatch(TodaysBadgesSuccess(data.payload));
      }
      else {
        await dispatch(TodaysBadgesSuccess(null));
      }
    } catch (err) {
      await dispatch(TodaysBadgesErr(err));
    }
  };
};



export {
  DailyLoginCountDataRead,
  CurrentweekYearwise,
  TodaysReadAssesmentBadges
};
