import { message, notification } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';

const addNotificationError = (err) => {
  notification.error({
    message: err,
  });
};

const updateNotificationError = (err) => {
  notification.error({
    message: err,
  });
};

const {
  submitQuizBegin,
  submitQuizSuccess,
  submitQuizErr,

  SaveQuizBegin,
  SaveQuizSuccess,
  SaveQuizErr,

  getQuizBegin,
  getQuizSuccess,
  getQuizErr,

  getQuizResultBegin,
  getQuizResultSuccess,
  getQuizResultErr,

} = actions;


const SubmitAllQuiz = (obj) => {
  return async (dispatch) => {
    try {
      await dispatch(submitQuizBegin());
      const response = await DataService.post(`/quizattempts/submit-quiz`,obj);
      const token = getItem('access_token');
      const data = response.data
      if (token && data !== null && data.Error === false) {
        await dispatch(submitQuizSuccess(response.data));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(submitQuizSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(submitQuizSuccess(null));
        }
      }
      else {
        await dispatch(submitQuizErr('Unauthorized access'));
        addNotificationError('Unauthorized access');
      }
    } catch (err) {
      await dispatch(submitQuizErr(err));
      addNotificationError(err);
    }
  };
};

const SaveQuizAnswer = (formData) => {
  return async (dispatch) => {
    try {
      await dispatch(SaveQuizBegin());
      const response = await DataService.post(`/quizquestionattempts/save-user-quiz-answer`, formData);
      const token = getItem('access_token');
      const data = response.data;
      if (token && response.data !== null && response.data.Error === false) {
        await dispatch(SaveQuizSuccess(response.data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(SaveQuizSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(SaveQuizSuccess(null));
        }
      }
      else {
        await dispatch(SaveQuizSuccess(null));
      }
    } catch (err) {
      await dispatch(SaveQuizErr(err));
      updateNotificationError(err);
    }
  };
};

// student
const getQuizByTopicToAttempt = (topicId) => {
  return async (dispatch) => {
    try {
      await dispatch(getQuizBegin());
      const query = await DataService.get(`/quizattempts/create-quiz/${topicId}`);
      const token = getItem('access_token');
      const data = query.data;
      if (token && query.data !== null & query.data.Error === false) {
        await dispatch(getQuizSuccess(query.data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(getQuizSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(getQuizSuccess(null));
        }
      }
      else {
        await dispatch(getQuizSuccess(null));
      }
    } catch (err) {
      await dispatch(getQuizErr(err));
    }
  };
};

const getQuizResultByTopic = (topicId) => {
  return async (dispatch) => {
    try {
      await dispatch(getQuizResultBegin());
      const query = await DataService.get(`/quizattempts/quiz-result/${topicId}`);
      const token = getItem('access_token');
      const data = query.data;
      if (token && data !== null && data.Error === false) {
        await dispatch(getQuizResultSuccess(query.data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(getQuizResultSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(getQuizResultSuccess(null));
        }
      }
      else {
        await dispatch(getQuizResultSuccess(null));
      }
    } catch (err) {
      await dispatch(getQuizResultErr(err));
    }
  };
};

export {
  SaveQuizAnswer,
  getQuizByTopicToAttempt,
  SubmitAllQuiz,
  getQuizResultByTopic
};
