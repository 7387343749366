const actions = {

  READ_CHUNK_BEGIN : 'READ_CHUNK_BEGIN',
  READ_CHUNK_SUCCESS:'READ_CHUNK_SUCCESS',
  READ_CHUNK_ERR : 'READ_CHUNK_ERR',  
  
  UPDATE_CHUNK_BEGIN: 'UPDATE_CHUNK_BEGIN',
  UPDATE_CHUNK_SUCCESS: 'UPDATE_CHUNK_SUCCESS',
  UPDATE_CHUNK_ERR: 'UPDATE_CHUNK_ERR',
  
  DELETE_CHUNK_BEGIN: 'DELETE_CHUNK_BEGIN',
  DELETE_CHUNK_SUCCESS: 'DELETE_CHUNK_SUCCESS',
  DELETE_CHUNK_ERR: 'DELETE_CHUNK_ERR',


  readChunkBegin: () => {
    return {
      type: actions.READ_CHUNK_BEGIN,
    };
  },

  readChunkSuccess: (data) => {
    return {
      type: actions.READ_CHUNK_SUCCESS,
      data,
    };
  },

  readChunkErr: (err) => {
    return {
      type: actions.READ_CHUNK_ERR,
      err,
    };
  },


  updateChunkBegin: () => {
    return {
      type: actions.UPDATE_CHUNK_BEGIN,
    };
  },

  updateChunkSuccess: (data) => {    
    return {
      type: actions.UPDATE_CHUNK_SUCCESS,      
      data,
    };
  },

  updateChunkErr: (err) => {
    return {
      type: actions.UPDATE_CHUNK_ERR,
      err,
    };
  },

  deleteChunkBegin: () => {
    return {
      type: actions.DELETE_CHUNK_BEGIN,
    };
  },

  deleteChunkSuccess: (data) => {    
    return {
      type: actions.DELETE_CHUNK_SUCCESS,      
      data,
    };
  },

  deleteChunkErr: (err) => {
    return {
      type: actions.DELETE_CHUNK_ERR,
      err,
    };
  },
};

export default actions;
