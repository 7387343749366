import React, { useEffect, useState } from 'react';
import { Modal, Input, Select, Table, message, Collapse, Checkbox } from 'antd';
import { Button } from '../../components/buttons/buttons';
// import 'antd/dist/antd.css';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import { DataTableStyleWrap } from '../../components/table/Style';
import { useDispatch, useSelector } from 'react-redux';
import { getQaReports } from '../../redux/answers/actionCreator';
import { readQaReportsSuccess } from '../../redux/answers/actions';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';
import UilMinus from '@iconscout/react-unicons/icons/uil-minus';
import UilEye from '@iconscout/react-unicons/icons/uil-eye';
import UilEyeSlash from '@iconscout/react-unicons/icons/uil-eye-slash';
import { CSVLink } from 'react-csv';
import { FaqWrapper } from './styles/tablestyle';
import UilSearch from '@iconscout/react-unicons/icons/uil-search';
import { getTeacherRegulationSubjects } from '../../redux/teacherSubjectsUnits/actionCreator';
import actions from '../../redux/teacherSubjectsUnits/actions';

const { TeacherSubjectsSuccess } = actions

const { Panel } = Collapse;

function QaReports() {

  const dispatch = useDispatch()
  const [subjectId, setSubjectId] = useState(0)
  const [unitId, setUnitId] = useState(0);
  const [topicId, setTopicId] = useState(0);    
  const [branchId, setBranchId] = useState(0);
  const [selectedStudentQA, setSelectedStudentQA] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [units, setUnits] = useState([]);
  const [topics, setTopics] = useState([]);

  const [rowInfo, setRowInfo] = useState(null);
  const [showCorrectAns, setShowCorrectAns] = useState(false);

  const { StuQaReports, StuQaReportsLoading, SubjectsUnits, SubjectsUnitsLoading, TeacherRegSubjects, TeacherRegSubjectsLoading } = useSelector((state) => {
    return {
      StuQaReports: state.StuQaReports.data,
      StuQaReportsLoading: state.StuQaReports.loading,
      SubjectsUnits: state.SubjectsUnits.data,
      SubjectsUnitsLoading: state.SubjectsUnits.loading,
      TeacherRegSubjects: state.TeacherRegSubjects.data,
      TeacherRegSubjectsLoading: state.TeacherRegSubjects.loading,
    }
  })

  const sectionsSet = new Set();
  StuQaReports.forEach(stuObj => {
    stuObj.Section !== null && sectionsSet.add(stuObj.Section);
  });
  const sections = [...sectionsSet]

  useEffect(() => {
    dispatch(readQaReportsSuccess([]))
    dispatch(TeacherSubjectsSuccess([]))
    dispatch(getTeacherRegulationSubjects())
  }, [])

  const columns = StuQaReports.length !== 0 && StuQaReports[0].columns.map((columnName) =>
  (
    columnName === "Section" ?
      ({
        title: columnName,
        dataIndex: columnName, // Convert spaces to underscores for dataIndex
        key: columnName.toLowerCase().replace(/ /g, '_'), // Use a unique key for each column
        filters: sections.length !== 0 && sections.map(section => ({ text: section, value: section })),
        onFilter: (value, record) => record.Section === value,
        className: 'text-ellipsis',
        width: 150
      })
      :
      columnName === "Username" ?
        ({
          title: columnName,
          dataIndex: columnName, // Convert spaces to underscores for dataIndex
          key: columnName.toLowerCase().replace(/ /g, '_'), // Use a unique key for each column                    
          className: 'text-ellipsis',
          width: 130
        })
        :
        columnName === "Name" ?
          ({
            title: columnName,
            dataIndex: columnName, // Convert spaces to underscores for dataIndex
            key: columnName.toLowerCase().replace(/ /g, '_'), // Use a unique key for each column                    
            className: 'text-ellipsis',
            width: 240
          })
          :
          columnName === "S.No" ?
            ({
              title: columnName,
              dataIndex: columnName, // Convert spaces to underscores for dataIndex
              key: columnName.toLowerCase().replace(/ /g, '_'), // Use a unique key for each column                    
              className: 'text-ellipsis',
              width: 50
            })
            :
            ({
              title: columnName,
              dataIndex: columnName, // Convert spaces to underscores for dataIndex
              key: columnName.toLowerCase().replace(/ /g, '_'), // Use a unique key for each column
              ellipsis: true,
              className: 'text-ellipsis',
            })
  )
  );


  const [tableData, setTableData] = useState([])
  const [filteredData, setFilteredData] = useState([])

  useEffect(() => {
    let data = []
    StuQaReports.length !== 0 && StuQaReports.map((obj, index) => {

      data.push({
        key: index,
        "S.No": index + 1,
        ...obj
      })
    })
    setTableData(data)
    setFilteredData(data)
  }, [StuQaReports])



  useEffect(() => {
    if (subjectId !== 0) {
      const selectedSubject = SubjectsUnits.subjects.find(subject => subject.id === subjectId);
      setUnits(selectedSubject.units);
    }
    else {
      setUnits([]);
    }
  }, [subjectId, SubjectsUnits?.subjects]);

  useEffect(() => {
    if (unitId !== 0) {
      const selectedUnit = units.find(unit => unit.id === unitId);
      setTopics(selectedUnit?.topics);
    } else {
      setTopics([]);
    }
  }, [unitId, SubjectsUnits?.subjects?.units]);

  const loadData = () => {
    if (branchId !== 0 && unitId !== 0) {
      dispatch(getQaReports(branchId, unitId));
    } else {
      message.warn("Select all filters!!");
    }
  };

  const searchkeys = ['Username', 'Name']
  const searchStudent = (e) => {
    const { value } = e.currentTarget;
    const searchData = tableData.length !== 0 && tableData.filter((obj) => searchkeys.some((key) => obj[key].toLowerCase().includes(value.toLowerCase())))
    setFilteredData(searchData)
  };

  const getTopicName = (topics, topicId) => {
    const topicInfo = topics.filter((topic) => topic.id == topicId)
    return topicInfo.length !== 0 && topicInfo[0].name
  }

  //export
  const headers = StuQaReports.length !== 0 ? StuQaReports[0].columns.map((columnName) => (
    { label: columnName, key: columnName }
  )) : []

  const csvReport = {
    filename: "StudentsQAScore.csv",
    headers: headers,
    data: filteredData
  }

  return (
    <div>
      <Main className='pt-20'>
        <Cards title="Q & A Reports">
          <DataTableStyleWrap>
            <div className="ninjadash-datatable-filter">
              <div className="ninjadash-datatable-filter__left">

                <div className="ninjadash-datatable-filter__input">
                  <span className="label">Subject:</span>
                  <Select style={{ width: 400 }} className="ninjadash-data-status" placeholder="Select Subject" onChange={(e) => { setSubjectId(e); setUnitId(0); setBranchId(0); }} loading={TeacherRegSubjectsLoading}>
                    {
                      TeacherRegSubjects.length !== 0 && TeacherRegSubjects.sort((a, b) => a.name > b.name ? 1 : -1).map((subjectObj, subjectIndex) =>
                        <Select.Option value={subjectObj.id} key={subjectIndex}>{subjectObj.name}</Select.Option>
                      )
                    }
                  </Select>
                </div>
                <div className="ninjadash-datatable-filter__input">
                  <span className="label">Unit:</span>
                  <Select style={{ width: 200 }} className="ninjadash-data-status" placeholder="Select Unit" value={unitId} onChange={(e) => { setUnitId(e); }} loading={TeacherRegSubjectsLoading}>
                    <Select.Option value={0} key={'selectUnit'} >Select</Select.Option>
                    {TeacherRegSubjects.length !== 0 && subjectId !== 0 && TeacherRegSubjects.filter((obj) => obj.id === subjectId)[0].units.length !== 0 && TeacherRegSubjects.filter((obj) => obj.id === subjectId)[0].units.map((unit) => (
                      <Select.Option key={unit.id} value={unit.id}>
                        {unit.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>                                
                <div className="ninjadash-datatable-filter__input">
                  <span className="label">Branch:</span>
                  <Select style={{ width: 200 }} className="ninjadash-data-status" placeholder="Select Branch" value={branchId} onChange={(e) => { setBranchId(e); }} loading={TeacherRegSubjectsLoading}>
                    <Select.Option value={0} key={'selectReg'} >Select</Select.Option>
                    {
                      TeacherRegSubjects.length !== 0 && subjectId !== 0 && unitId !== 0 &&
                      TeacherRegSubjects.filter((obj) => obj.id === subjectId)[0].branches.length !== 0 && TeacherRegSubjects.filter((obj) => obj.id === subjectId)[0].branches.map((branch) => (
                        <Select.Option key={branch.id} value={branch.id}>
                          {branch.code}
                        </Select.Option>
                      ))
                    }
                  </Select>
                </div>
                <div className="ninjadash-datatable-filter__action">
                  <Button type="primary" size="small" onClick={() => { loadData() }}>
                    Get
                  </Button>
                </div>
                {/* <div className="ninjadash-datatable-filter__action">
                  <Button
                    type="primary"
                    size="small"
                    onClick={handleCompareClick}
                    style={{ margin: 10 }}
                  >
                    Compare
                  </Button>
                </div> */}
              </div>
            </div>
            <div className='d-flex justify-content-between align-center-v'>
              <p className='fontsize-12'>Note: <i>NA - Not Attempted</i></p>
              <div className="mb-20 d-flex align-center-v" style={{ maxWidth: '380px', marginLeft: 'auto' }}>
                <CSVLink {...csvReport}>
                  <Button id="Answers_export-button" type="primary" size="small" className="mr-10" disabled={tableData.length !== 0 ? false : true}>
                    Export
                  </Button>
                </CSVLink>
                <Input className='' onChange={searchStudent} size="default" placeholder="Search by Rollno. or Name" prefix={<UilSearch />} />
              </div>
            </div>
            {
              StuQaReportsLoading ?
                <div style={{ width: '100%', height: '400px' }}>
                  <div className="custom-spin">
                    <CustomSpinner Message={'Fetching data... Please wait.'} />
                  </div>
                </div>
                :
                <div>
                  <Table
                    className='table-responsive'
                    pagination={{
                      defaultPageSize: 50,
                      total: filteredData.length,
                      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    }}
                    columns={columns}
                    dataSource={filteredData}
                    tableLayout='auto'
                    size='middle'
                  />
                </div>
            }
          </DataTableStyleWrap>
        </Cards>
        <Modal
          title={`Answers of ${rowInfo !== null ? rowInfo.studentUsername : ''} - ${rowInfo !== null ? rowInfo.studentName : ''} `}
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
          width={1200}
        >
          <div className='height-fixed'>
            <div className='mb-20 d-flex'>
              <p className='m-0 mr-10 fontsize-16'>Topic: </p><h3 className='m-0'>{topics.length !== 0 ? getTopicName(topics, topicId) : ''}</h3>
            </div>
            <div className='mb-20 d-flex'>
              <p className='m-0 mr-10 fontsize-14'>Avg Score: </p><h3 className='m-0 mr-10 fontsize-14'>{rowInfo !== null && rowInfo.score}</h3>
              <p className='m-0 mr-10 fontsize-14'>QA Score: </p><h3 className='m-0 mr-10 fontsize-14'>{rowInfo !== null && rowInfo.qascore}</h3>
              <p className='m-0 mr-10 fontsize-14'>Program Score: </p><h3 className='m-0 fontsize-14'>{rowInfo !== null && rowInfo.programscore}</h3>
            </div>
            {selectedStudentQA.length !== 0 && selectedStudentQA.sort((a, b) => a.updatedon < b.updatedon ? 1 : -1).map((qa, index) => (
              <div key={index}>
                <FaqWrapper>
                  <Collapse defaultActiveKey={['0']} bordered={false} expandIcon={({ isActive }) => (isActive ? <UilMinus /> : <UilPlus />)} className='m-0 mb-5' >
                    <Panel header={
                      <div className='d-flex justify-content-between'>
                        <span>{qa.qaMaster.question}</span>
                        <div>
                          <span className='mr-10'>Score : {qa.score} of 10</span>
                          <span className='text-right fontsize-13 m-0'>| Submitted on : {new Date(qa.updatedon).toLocaleString()}</span>
                        </div>
                      </div>
                    } key={index}>
                      <h4 className='align-center-v'>Correct Answer {showCorrectAns ? <UilEye size={18} className="ml-10 cursor-pointer" onClick={() => { setShowCorrectAns(false) }} /> : <UilEyeSlash size={18} className="ml-10 cursor-pointer" onClick={() => { setShowCorrectAns(true) }} />}</h4>
                      {
                        showCorrectAns &&
                        <p style={{ backgroundColor: '#fdf2ef' }}>{qa.correctAnswer}</p>
                      }
                      <h4>Student Answer</h4>
                      <p style={{ textAlign: 'justify' }} className='m-0'>
                        {qa.userAnswer === null ? 'N/A' : qa.userAnswer}
                      </p>
                      <h4 className='mt-10'>Evaluation Feedback1</h4>
                      <p style={{ textAlign: 'justify', paddingBottom: '15px' }} className='m-0'>
                        {qa.eSummary1 === null ? 'NA' : qa.eSummary1}
                      </p>
                      <div className='mb-20'>{qa.eSummary1 !== null && "Evaluation Score1: " + qa.eScore1}</div>
                      <h4>Evaluation Feedback2</h4>
                      <p style={{ textAlign: 'justify', paddingBottom: '15px' }} className='m-0'>
                        {qa.eSummary2 === null ? 'NA' : qa.eSummary2}
                      </p>
                      <div className='mb-20'>{qa.eSummary2 !== null && "Evaluation Score2: " + qa.eScore2}</div>
                      <h4>Evaluation Feedback3</h4>
                      <p style={{ textAlign: 'justify', paddingBottom: '15px' }} className='m-0'>
                        {qa.eSummary3 === null ? 'NA' : qa.eSummary3}
                      </p>
                      <div className='mb-20'>{qa.eSummary3 !== null && "Evaluation Score2: " + qa.eScore3}</div>
                    </Panel>
                  </Collapse>
                </FaqWrapper>
              </div>
            ))}
          </div>
        </Modal>
      </Main>
    </div>
  );
}

export default QaReports;
