import { Button, Form, Input, Modal, Select } from 'antd';
import react, { useEffect } from 'react';
import { FormValidationWrap } from '../forms/overview/Style';
import { useDispatch, useSelector } from 'react-redux';
import { CreateNewTopic } from '../../redux/topics/actionCreator'
import actions from '../../redux/topics/actions'
import { subjectsUnitsDataRead } from '../../redux/teacherSubjectsUnits/actionCreator';

const { createTopicSuccess } = actions

const { Option } = Select
function CreateTopic({ open, close }) {

    const [form] = Form.useForm();
    const dispatch = useDispatch()
    const { SubjectsUnits, CreateTopicRes, CreatingNewTopic } = useSelector((state) => {
        return {
            SubjectsUnits: state.SubjectsUnits.data,
            CreateTopicRes: state.CreateTopicRes.data,
            CreatingNewTopic: state.CreateTopicRes.loading,
        };
    });

    useEffect(() => {
        dispatch(createTopicSuccess(null))
    },[])

    useEffect(() => {
        if (CreateTopicRes !== null && CreateTopicRes.Error === false) {
            form.resetFields()
            close(false)
            dispatch(createTopicSuccess(null))
            dispatch(subjectsUnitsDataRead());
        }
    }, [CreateTopicRes])

    const AddTopic = (formData) => {
        dispatch(CreateNewTopic(formData))
    }

    return (
        <div>
            <Modal open={open} title="Create Topic" footer={false} onCancel={() => close(false)}>
                <FormValidationWrap>
                    <Form name="sDash_validation-form" form={form} layout="vertical" onFinish={AddTopic}>
                        <Form.Item
                            name="subjectId"
                            rules={[{ required: true, message: 'Subject required!' }]}
                            label="Subject"
                        >
                            <Select placeholder="Select">
                                {
                                    SubjectsUnits !== null && SubjectsUnits.subjects.length !== 0 && SubjectsUnits.subjects.map((sub, index) =>
                                        <Option key={index} value={sub.id}>{sub.name}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="unitname"
                            rules={[{ required: true, message: 'Unit required!' }]}
                            label="Unit"
                        >
                            <Select placeholder="Select">
                                <Option value='UNIT-I'>UNIT-I</Option>
                                <Option value='UNIT-II'>UNIT-II</Option>
                                <Option value='UNIT-III'>UNIT-III</Option>
                                <Option value='UNIT-IV'>UNIT-IV</Option>
                                <Option value='UNIT-V'>UNIT-V</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="topicname"
                            rules={[{ required: true, message: 'Topic Name required!' }]}
                            label="Topic Name"
                        >
                            <Input size="medium" placeholder="Topic name" />
                        </Form.Item>

                        <div className=' d-flex justify-content-end mb-20'>
                            <Button htmlType="reset" type="secondary" className='mr-10' >
                                Reset
                            </Button>
                            <Button htmlType="submit" type="primary" disabled={CreatingNewTopic} >
                                {CreatingNewTopic ? 'Creating...' : 'Create'}
                            </Button>
                        </div>
                    </Form>
                </FormValidationWrap>
            </Modal>
        </div>
    )
}


export default CreateTopic