import actions from './actions';

const {
  GENERATE_QUESTIONS_ADD_BEGIN,
  GENERATE_QUESTIONS_ADD_SUCCESS,
  GENERATE_QUESTIONS_ADD_ERR,

  GENERATE_QUESTIONS_READ_BEGIN,
  GENERATE_QUESTIONS_READ_SUCCESS,
  GENERATE_QUESTIONS_READ_ERR,

  QA_UPDATE_BEGIN,
  QA_UPDATE_SUCCESS,
  QA_UPDATE_ERR,
  
  READ_QUESTIONS_BEGIN,
  READ_QUESTIONS_SUCCESS,
  READ_QUESTIONS_ERR,

  DELETE_QA_BEGIN,
  DELETE_QA_SUCCESS,
  DELETE_QA_ERR,

  ANSWER_EVALUATE_BEGIN,
  ANSWER_EVALUATE_SUCCESS,
  ANSWER_EVALUATE_ERR,

  GET_ATTEMPTED_QA_BEGIN,
  GET_ATTEMPTED_QA_SUCCESS,
  GET_ATTEMPTED_QA_ERR

} = actions;

const postResponseInitialState = {
  data: null,    
  loading: false,
  error: null,
};

const getInitialState = {
  data: null,
  loading: false,
  error: null,
};

const QAUpdateInitialState = {
  data:null,
  loading:false,
  error:null,
}

const getAllQAInitialState = {
  data:null,
  loading:false,
  error:null,
}

const delQuizInitialState = {
  data:null,
  loading:false,
  error:null,
}

const ansEvaluateInitialState = {
  data:null,
  loading:false,
  error:null,
}

const attemptedQA = {
  data:null,
  loading:false,
  error:null,
}

const postResponseReducer = (state = postResponseInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    
    case GENERATE_QUESTIONS_ADD_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GENERATE_QUESTIONS_ADD_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GENERATE_QUESTIONS_ADD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const ReadQAReducer = (state = getInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GENERATE_QUESTIONS_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GENERATE_QUESTIONS_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GENERATE_QUESTIONS_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};


const UpdateQAReducer = (state = QAUpdateInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case QA_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case QA_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case QA_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const ReadAllQAByTopicReducer = (state = getAllQAInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case READ_QUESTIONS_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case READ_QUESTIONS_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case READ_QUESTIONS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const DeleteQAReducer = (state = delQuizInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case DELETE_QA_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case DELETE_QA_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case DELETE_QA_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const AnswerEvaluateReducer = (state = ansEvaluateInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ANSWER_EVALUATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ANSWER_EVALUATE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case ANSWER_EVALUATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const GETATTEMPTEDQAReducer = (state = attemptedQA, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_ATTEMPTED_QA_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_ATTEMPTED_QA_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GET_ATTEMPTED_QA_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { postResponseReducer, ReadQAReducer, UpdateQAReducer, ReadAllQAByTopicReducer, DeleteQAReducer, AnswerEvaluateReducer, GETATTEMPTEDQAReducer };
