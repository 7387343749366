import { READ_STUDENT_RECENT_ACTIONS_BEGIN, READ_STUDENT_RECENT_ACTIONS_SUCCESS, READ_STUDENT_RECENT_ACTIONS_ERROR } from './actions';

const initialState = {
    data: [],
    loading: false,
    error: null,
};

const StudentRecentActionsDataReducer = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case READ_STUDENT_RECENT_ACTIONS_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case READ_STUDENT_RECENT_ACTIONS_SUCCESS:
            return {
                ...state,
                data,
                error: false,
                loading: false,
            };
        case READ_STUDENT_RECENT_ACTIONS_ERROR:
            return {
                ...state,
                error : err,
                loading: false,
            };
        default:
            return state;
    }
};


export { StudentRecentActionsDataReducer };
