import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { getItem } from '../../utility/localStorageControl';
import { logOut } from '../authentication/actionCreator';
import { message } from 'antd';


const {
  subjectsUnitsReadBegin,
  subjectsUnitsReadSuccess,
  subjectsUnitsReadErr,
  subjectReadBegin,
  subjectReadSuccess,
  subjectReadErr,
  unitReadBegin,
  unitReadSuccess,
  unitReadErr,
  TeacherSubjectsBegin,
  TeacherSubjectsSuccess,
  TeacherSubjectsErr
} = actions;


const subjectsUnitsDataRead = () => {
  return async (dispatch) => {
    try {      
      await dispatch(subjectsUnitsReadBegin());
      const query = await DataService.get(`/teachermaster/units`);
      const token = getItem('access_token');
      const data = query.data;
      if (token && data !== null && data.Error === false) {
        await dispatch(subjectsUnitsReadSuccess(query.data));
      }
      else {
        await dispatch(subjectsUnitsReadSuccess(null));
        // await dispatch(subjectsUnitsAddErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(subjectsUnitsReadErr(err));
    }
  };
};

const getSubjectById = (subjectId) => {
  return async (dispatch) => {
    try {
      await dispatch(subjectReadBegin());
      const query = await DataService.get(`/subjectmaster/single/${subjectId}`);
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(subjectReadSuccess(query.data.payload));
      } else {
        await dispatch(subjectReadSuccess(null));
        // await dispatch(subjectsUnitsAddErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(subjectReadErr(err));
    }
  };
};

const getUnitById = (unitId) => {
  return async (dispatch) => {
    try {
      await dispatch(unitReadBegin());
      const query = await DataService.get(`/unitmaster/${unitId}`);
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(unitReadSuccess(query.data.payload));
      } else {
        await dispatch(unitReadSuccess(null));
        // await dispatch(subjectsUnitsAddErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(unitReadErr(err));
    }
  };
};

const getTeacherRegulationSubjects = () => {
  return async (dispatch) => {
    try {
      await dispatch(TeacherSubjectsBegin());
      const query = await DataService.get(`/subjectmaster/teacher`);
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(TeacherSubjectsSuccess(query.data.payload));
      }
      else if (query.data !== null && query.data.Error === true) {
        if (Object.keys(query.data).length >= 3 && query.data.statusCode == 401) {
          message.error(query.data.message)
          await dispatch(TeacherSubjectsSuccess([]));
          dispatch(logOut())
        }
        else {
          await dispatch(TeacherSubjectsSuccess([]));
        }
      }
      else {
        await dispatch(TeacherSubjectsSuccess([]));
        // await dispatch(subjectsUnitsAddErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(TeacherSubjectsErr(err));
    }
  };
};

export {
  subjectsUnitsDataRead,
  getSubjectById,
  getUnitById,
  getTeacherRegulationSubjects
};