export const READ_QAF_BEGIN = 'READ_QAF_BEGIN';
export const READ_QAF_SUCCESS = 'READ_QAF_SUCCESS';
export const READ_QAF_ERROR = 'READ_QAF_ERROR';

export const readQAFBegin = () => ({
    type: READ_QAF_BEGIN,
});

export const readQAFSuccess = (data) => ({
    type: READ_QAF_SUCCESS,
    payload: data,
});

export const readQAFError = (error) => ({
    type: READ_QAF_ERROR,
    payload: error,
});
