import UilAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
import UilBell from '@iconscout/react-unicons/icons/uil-bell';
import UilFileAlt from '@iconscout/react-unicons/icons/uil-file-alt';
import UilDollarSign from '@iconscout/react-unicons/icons/uil-dollar-sign';
import UilSetting from '@iconscout/react-unicons/icons/uil-setting';
import UilSignout from '@iconscout/react-unicons/icons/uil-signout';
import UilUser from '@iconscout/react-unicons/icons/uil-user';
import UilMoon from '@iconscout/react-unicons/icons/uil-moon';
import UilWrench from '@iconscout/react-unicons/icons/uil-wrench';
import { Avatar } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
import Message from './Message';
import Notification from './Notification';
import Search from './Search';
import Settings from './settings';
import { logOut } from '../../../redux/authentication/actionCreator';
import { Button } from '../../../components/buttons/buttons';

import { Dropdown } from '../../dropdown/dropdown';
import Heading from '../../heading/heading';
import { Popover } from '../../popup/popup';
import { changeLayoutMode } from '../../../redux/themeLayout/actionCreator';
import { getItem, setItem } from '../../../utility/localStorageControl';
import 'animate.css';

const StudentAuthInfo = React.memo(() => {
  const dispatch = useDispatch();

  const LayoutMode = getItem('mode')

  const [state, setState] = useState({
    flag: 'en',
  });
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { flag } = state;

  const SignOut = (e) => {
    e.preventDefault();

    dispatch(logOut(() => navigate('/')));
  };

  useEffect(() => {
    if (LayoutMode === 'darkMode') {
      document.body.classList.remove('light-mode');
      document.body.classList.add('dark-mode');
    }
    else if (LayoutMode === 'darkMode') {
      document.body.classList.remove('dark-mode');
      document.body.classList.add('light-mode');
    }
  }, [])

  const profilename = getItem('profilename')
  const collegeName = getItem('collegeName')
  const username = getItem('username')

  const name = profilename.split(' ')

  const changeLayout = (mode) => {
    dispatch(changeLayoutMode(mode));
  };

  const switchMode = () => {
    if (LayoutMode === 'lightMode' || LayoutMode === undefined) {
      setItem('mode', 'darkMode')
      changeLayout('darkMode')
      document.body.classList.remove('light-mode');
      document.body.classList.add('dark-mode');
    }
    else if (LayoutMode === 'darkMode') {
      setItem('mode', 'lightMode')
      changeLayout('lightMode')
      document.body.classList.remove('dark-mode');
      document.body.classList.add('light-mode');
    }
  }

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img src={require('../../../static/img/avatar/chat-auth.png')} alt="" />
          <figcaption>
            <Heading as="h5">{name.length >= 2 ? name[0][0].toUpperCase() + name[0].slice(1) + ' ' + name[1].toLowerCase() : name[0][0].toUpperCase() + name[0].slice(1)}</Heading>
            <p>{username}</p>
            <p>{collegeName}</p>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to="student-profile">
              <UilUser /> Profile
            </Link>
          </li>
          {/* <li>
            <Link onClick={switchMode}>
              <UilMoon /> Switch Mode
            </Link>
          </li> */}
          {/* <li>
            <Link to="#">
              <UilFileAlt /> My Reports
            </Link>
          </li> */}
          {/* <li>
            <Link to="#">
              <UilBell /> Help
            </Link>
          </li> */}
          {/* <li>
            <Link to="change-password">
              <UilWrench /> Password
            </Link>
          </li> */}
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <UilSignout /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  const onFlagChangeHandle = (value, e) => {
    e.preventDefault();
    setState({
      ...state,
      flag: value,
    });
    i18n.changeLanguage(value);
  };

  const country = (
    <NavAuth>
      <Link onClick={(e) => onFlagChangeHandle('en', e)} to="#">
        <img src={require('../../../static/img/flag/en.png')} alt="" />
        <span>English</span>
      </Link>
      <Link onClick={(e) => onFlagChangeHandle('esp', e)} to="#">
        <img src={require('../../../static/img/flag/esp.png')} alt="" />
        <span>Spanish</span>
      </Link>
      <Link onClick={(e) => onFlagChangeHandle('ar', e)} to="#">
        <img src={require('../../../static/img/flag/ar.png')} alt="" />
        <span>Arabic</span>
      </Link>
    </NavAuth>
  );

  useEffect(() => {
    setInterval(function () {
      let FS_NAME = document.getElementById('FS_NAME')
      FS_NAME && FS_NAME.classList.remove("animate__rubberBand");
      setTimeout(function () {
        FS_NAME && FS_NAME.classList.add("animate__rubberBand");
      }, 1500);
    }, 10000);
  }, [])

  return (
    <InfoWraper>
      {/* <div style={{ color: '#006666', fontSize: '13px', textAlign: 'right', backgroundColor: '#e5efef', paddingLeft: '10px', paddingRight: '10px', marginRight: '10px' }}>
        Please note all data created is temporary and some features may not be available yet.
      </div> */}
      {/* <div>Week-2</div> */}
      {/* <Search /> */}
      {/* <Message />
      <Notification />
      <Settings /> */}
      {/* <div className="ninjadash-nav-actions__item ninjadash-nav-actions__language">
        <Dropdown placement="bottomRight" content={country} trigger="click">
          <Link to="#" className="ninjadash-nav-action-link">
            <img src={require(`../../../static/img/flag/${flag}.png`)} alt="" />
          </Link>
        </Dropdown>
      </div> */}

      <span style={{ color: 'black', fontWeight: '500' }} id="FS_NAME" className="animate__animated animate__rubberBand mr-10">{getItem('fsName')}</span>

      <div className="ninjadash-nav-actions__item ninjadash-nav-actions__author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="ninjadash-nav-action-link">
            <Avatar>{name.length >= 2 ? name[0][0].toUpperCase() + name[1][0].toUpperCase() : name[0][0].toUpperCase()}</Avatar>
            <span className="ninjadash-nav-actions__author--name">{name.length >= 2 ? name[0][0].toUpperCase() + name[0].slice(1) + ' ' + name[1].toLowerCase() : name[0][0].toUpperCase() + name[0].slice(1)}</span>
            <UilAngleDown />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
});

export default StudentAuthInfo;
