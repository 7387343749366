import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

const getItem = (key) => {
  const data = Cookies.get(key);
  if (data === undefined) {
    return data
  }
  const decrypted = CryptoJS.AES.decrypt(data, process.env.REACT_APP_TOKEN_SECRET);
  const originalData = decrypted.toString(CryptoJS.enc.Utf8);
  try {
    return JSON.parse(originalData);
  } catch (err) {
    return originalData;
  }
};

const setItem = (key, value) => {
  const stringify = typeof value !== 'string' ? JSON.stringify(value) : value;
  const encrypted = CryptoJS.AES.encrypt(stringify, process.env.REACT_APP_TOKEN_SECRET).toString();
  return Cookies.set(key, encrypted);
};

const removeItem = (key) => {
  Cookies.remove(key);
};

export { getItem, setItem, removeItem };
