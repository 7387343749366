import React from 'react';
import { Cards } from '../../components/cards/frame/cards-frame';
import Heading from '../../components/heading/heading';
import { NoteCardWrap } from '../note/style';
import { Col, Result, Row } from 'antd';
import './customstyles.css'
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';

function VideoPage({ topicsLoading, refVideoURL, refVideoDescription, topicName }) {

    const formattedUrl = (url) => {
        if (url !== "null") {
            const videoId = url && url.match(/(?:\/|v=|be\/|embed\/)([\w-]{11})/)[1];
            return `https://www.youtube.com/embed/${videoId}`;
        }
        else {
            return null
        }
    }
    
    return (
        <div>
            <Cards headless>
                <Heading as={'h3'} className='text-center'>{topicName}</Heading>
                <NoteCardWrap>
                    {
                        topicsLoading ?
                            <div className="custom-spin">
                                <CustomSpinner />
                            </div>
                            :
                            <div>                               
                                {
                                    refVideoURL !== null ?
                                        <div className='mt-20 text-center'>
                                            <h3 className='m-0 mt-20'>Reference Video</h3>
                                            <p className='m-0 mb-10'>Reference Description : {refVideoDescription !== null ? refVideoDescription : ''}</p>
                                            <iframe
                                                className='videoframe'
                                                src={formattedUrl(refVideoURL)}
                                                title="Video player"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                allowFullScreen>
                                            </iframe>
                                        </div>
                                        :
                                        <div className='custom-spin'>
                                            <Result
                                                status={'warning'}
                                                title="Reference video not available"
                                            />
                                        </div>
                                }
                            </div>
                    }
                </NoteCardWrap>
            </Cards>
        </div>
    );
}

export default VideoPage;