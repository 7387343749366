const actions = {

  DAILY_LOGIN_COUNT_BEGIN: 'DAILY_LOGIN_COUNT_BEGIN',
  DAILY_LOGIN_COUNT_SUCCESS: 'DAILY_LOGIN_COUNT_SUCCESS',
  DAILY_LOGIN_COUNT_ERR: 'DAILY_LOGIN_COUNT_ERR',

  CURRENT_WEEK_BEGIN: 'CURRENT_WEEK_BEGIN',
  CURRENT_WEEK_SUCCESS: 'CURRENT_WEEK_SUCCESS',
  CURRENT_WEEK_ERR: 'CURRENT_WEEK_ERR',
  
  TODAYS_BADGES_BEGIN: 'TODAYS_BADGES_BEGIN',
  TODAYS_BADGES_SUCCESS: 'TODAYS_BADGES_SUCCESS',
  TODAYS_BADGES_ERR: 'TODAYS_BADGES_ERR',


  dailylogincountBegin: () => {
    return {
      type: actions.DAILY_LOGIN_COUNT_BEGIN,
    };
  },

  dailylogincountSuccess: (data) => {
    return {
      type: actions.DAILY_LOGIN_COUNT_SUCCESS,
      data,
    };
  },

  dailylogincountErr: (err) => {
    return {
      type: actions.DAILY_LOGIN_COUNT_ERR,
      err,
    };
  },

  currentweekBegin: () => {
    return {
      type: actions.CURRENT_WEEK_BEGIN,
    };
  },

  currentweekSuccess: (data) => {
    return {
      type: actions.CURRENT_WEEK_SUCCESS,
      data,
    };
  },

  currentweekErr: (err) => {
    return {
      type: actions.CURRENT_WEEK_ERR,
      err,
    };
  },

  TodaysBadgesBegin: () => {
    return {
      type: actions.TODAYS_BADGES_BEGIN,
    };
  },

  TodaysBadgesSuccess: (data) => {
    return {
      type: actions.TODAYS_BADGES_SUCCESS,
      data,
    };
  },

  TodaysBadgesErr: (err) => {
    return {
      type: actions.TODAYS_BADGES_ERR,
      err,
    };
  },

};

export default actions;
