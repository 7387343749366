import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { Row, Col, Card, Skeleton, Table, Timeline, Progress, Avatar } from 'antd';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Cards } from '../../components/cards/frame/cards-frame';
import { BorderLessHeading, Main, TableDefaultStyle } from '../styled';
import { Link } from 'react-router-dom';
import { PerfomanceOverviewStyle, TopSellerWrap } from './styles/dashboardStyle';
import { TimeLinePointerIconWrap } from '../ui-elements/ui-elements-styled';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import UilTrashAlt from '@iconscout/react-unicons/icons/uil-trash-alt';
import UilFileUpload from '@iconscout/react-unicons/icons/uil-file-upload'
import { useDispatch, useSelector } from 'react-redux';
import { StarOutlined, EditOutlined, ReadOutlined, BookOutlined } from '@ant-design/icons';
import Chart from 'react-apexcharts';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList } from 'recharts';
import { progressDataRead } from '../../redux/teacherDashboard/actionCreator';
import { QAFDataRead } from '../../redux/QAFeedback/actionCreator';
import { ContentDataRead, UserFeedbackDataRead } from '../../redux/contentFeedback/actionCreator';
import { subjectsUnitsDataRead } from '../../redux/teacherSubjectsUnits/actionCreator';
import RadialChart from './DashboardOverview/RadialChart';
import ContentFeedback from './DashboardOverview/ContentFeedback';
import RecentActions from './DashboardOverview/RecentActions';
import { RecentActionsDataRead } from '../../redux/recentActions/actionCreator';
import SubjectProgress from './DashboardOverview/SubjectProgress';
import QAFeedback from './DashboardOverview/QAFeedback';
import StudentRelatedCounts from './DashboardOverview/StudentRelatedCounts';
import NoticeModal from './DashboardOverview/NoticeModal';


const TopicsOverview = ({ subjectUnits, ProgressData, CFData, QAFData, RecentActionsData }) => {

  const Columns = [
    {
      title: 'Topic',
      dataIndex: 'name',
      key: 'name',
      widht: 150
    },
    {
      title: 'Topic Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center'
    },
    {
      title: 'Questions',
      dataIndex: 'questionscount',
      key: 'questionscount',
      align: 'center'
    },
  ];

  return (
    <div className="full-width-table">
      <Row gutter={25}>
        {/* <Col xs={24}>
          <Suspense
            fallback={
              <Cards headless>
                <Skeleton active />
              </Cards>
            }
          >
            <StudentRelatedCounts />
          </Suspense>
        </Col> */}
        {
          ProgressData.length !== 0 &&
          <Col xl={12} lg={24} md={24} xs={24}>
            <Suspense
              fallback={
                <Cards headless>
                  <Skeleton active />
                </Cards>
              }
            >
              {/* <RadialChart ProgressData={ProgressData} /> */}
              <SubjectProgress ProgressData={ProgressData} />
            </Suspense>
          </Col>
        }
        {
          RecentActionsData.length !== 0 &&
          <Col xl={12} lg={24} md={24} xs={24}>
            <Suspense
              fallback={
                <Cards headless>
                  <Skeleton active />
                </Cards>
              }
            >
              <RecentActions RecentActions={RecentActionsData} />
            </Suspense>
          </Col>
        }
        {
          subjectUnits.length !== 0 && subjectUnits.map((subjectObj, subjectIndex) =>
            <Col xl={12} lg={24} md={24} xs={24} key={subjectIndex}>
              <Suspense
                fallback={
                  <Cards headless>
                    <Skeleton active />
                  </Cards>
                }
              >
                <TopicComponent subjectObj={subjectObj} Columns={Columns} />
              </Suspense>
            </Col>
          )
        }
      </Row>
      {
        CFData.length !== 0 &&
        <Suspense
          fallback={
            <Cards headless>
              <Skeleton active />
            </Cards>
          }
        >
          <ContentFeedback CFData={CFData} />
        </Suspense>
      }
      {
        QAFData.length !== 0 &&
        <Suspense
          fallback={
            <Cards headless>
              <Skeleton active />
            </Cards>
          }
        >
          <QAFeedback QAFData={QAFData} />
        </Suspense>
      }
    </div >
  )
}

const TopicComponent = ({ subjectObj, Columns }) => {

  const [topicsToDisplay, setTopicsToDisplay] = useState(false)

  const [selectedUnitState, setSelectedUnitState] = useState({
    selectedTab: 0,
  });

  const handleChangeUnit = (value, event) => {
    event.preventDefault();
    setSelectedUnitState({
      ...selectedUnitState,
      selectedTab: value,
    });
  };

  /* State destructuring */
  const { selectedTab } = selectedUnitState;

  const topicsData = [];

  if (subjectObj.units.length !== 0 && subjectObj.units[selectedTab].topics.length !== 0) {
    const count = topicsToDisplay ? subjectObj.units[selectedTab].topics.length : 4
    subjectObj.units[selectedTab].topics.slice(0, count).map((value, index) => {
      const { id, name, status, questionscount, updatedon } = value;
      return topicsData.push({
        "key": index,
        id,
        name,
        status: <span className={`status ${status === "CONTENT FREEZED" ? 'done' : 'pending'} fontsize-12`}>{status}</span>,
        questionscount,
        updatedon
      });
    });
  }

  return (
    <BorderLessHeading >
      <Cards
        isbutton={
          <div className="ninjadash-card-nav">
            <ul>
              {
                subjectObj.units.length !== 0 && subjectObj.units.sort((a, b) => a.name > b.name ? 1 : -1).map((unitObj, unitIndex) =>
                  <li key={unitIndex} className={selectedTab === unitIndex ? 'ninjadash-active' : 'ninjadash-today'}>
                    <Link onClick={(e) => handleChangeUnit(unitIndex, e)} to="#">
                      {unitObj.name}
                    </Link>
                  </li>
                )
              }
            </ul>
          </div>
        }
        title={subjectObj.name}
        size="large"
      >
        <TableDefaultStyle className="ninjadash-having-header-bg" style={{ minHeight: topicsData.length !== 0 && topicsData.length > 3 ? '220px' : '254px' }} >
          <TopSellerWrap>
            <div className="table-bordered top-seller-table table-responsive">
              <Table columns={Columns} dataSource={topicsData} pagination={false} />
            </div>
          </TopSellerWrap>
        </TableDefaultStyle>
        {
          topicsData.length !== 0 && topicsData.length > 3 &&
          <div style={{ marginTop: '10px' }}>
            <Link onClick={() => setTopicsToDisplay(!topicsToDisplay)}>{topicsToDisplay ? "See Less" : "See More"}</Link>
          </div>
        }
      </Cards>
    </BorderLessHeading>
  )
}


function Dashboard() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(subjectsUnitsDataRead());
    // dispatch(progressDataRead());
    // dispatch(RecentActionsDataRead());
    // dispatch(UserFeedbackDataRead());
    // dispatch(QAFDataRead());
  }, [dispatch])

  const { SubjectsUnits, ProgressData, CFData, QAFData, RecentActionsData } = useSelector((state) => {
    return {
      SubjectsUnits: state.SubjectsUnits.data,
      isLoading: state.SubjectsUnits.loading,
      ProgressData: state.ProgressData.data,
      CFData: state.CFData.data,
      QAFData: state.QAFeedbackData.data,
      RecentActionsData: state.RecentActions.data,
      ProgressData: state.ProgressData.data,
    };
  });

  return (
    <>
      <Main>
        <PageHeader className="ninjadash-page-header-main" title="Dashboard" />
        <Suspense
          fallback={
            <Cards headless>
              <Skeleton active />
            </Cards>
          }
        >
          <TopicsOverview subjectUnits={SubjectsUnits !== null ? SubjectsUnits.subjects : []} CFData={CFData} RecentActionsData={RecentActionsData} ProgressData={ProgressData} QAFData={QAFData} />
          <NoticeModal/>
        </Suspense>
      </Main>
    </>
  );
}
export default Dashboard;