import actions from './actions';

const {
  GET_SECTIONS_BEGIN,
  GET_SECTIONS_SUCCESS,
  GET_SECTIONS_ERR,
} = actions;

const getInitialState = {
  data: [],    
  loading: false,
  error: null,
};


const getSectionsReducer = (state = getInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_SECTIONS_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_SECTIONS_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GET_SECTIONS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};



export { getSectionsReducer };
